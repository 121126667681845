import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { BASE_URL, COD_URL, ORDERADDRESSLIST_URL } from '../../componant/config/constant';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './paymentsus.css'

export const Paymentsucess = () => {
    
    const navigate = useNavigate()

    const cartData = JSON?.parse(localStorage?.getItem('selectedItems'))?.map((e) => e = { ...e, amount: e?.count * e?.price })
    const orderDetailData = JSON.parse(localStorage?.getItem('orderData'))
    const selectedTimeSchedule = JSON.parse(localStorage.getItem('selectedDateTime'))
    const OrderAddressId =JSON.parse(localStorage?.getItem("selectedAddressId"))

    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;

    const [cartAmountData, setCartAmountData] = useState({
        total: '0.00',
        gst: 0.05,
        qst: 0,
        tip: 0.00,
        grandTotal: 0.00
    })

    const placeMyOrder = () => {
        
        const total = cartData.map(e => e.amount)?.reduce((a, b) => { return a + b }, 0);
        cartAmountData.total = total;
        cartAmountData.gstAmt = Number((total * cartAmountData?.gst).toFixed(2))
        cartAmountData.qstAmt = Number((total * cartAmountData?.qst).toFixed(2))
        cartAmountData.tipAmt = Number(((total * cartAmountData?.tip) / 100).toFixed(2))
        
        cartAmountData.grandTotal = Number(total + cartAmountData.gstAmt + cartAmountData?.qstAmt + cartAmountData?.tipAmt).toFixed(2)

        const placeOrderData = {
            cart:cartData.map(item => ({
                product_id: item.selectedItemId || item.productId ||  [],
                price: item.price || [],
                variant: [],
                variations: [],
                discount_amount: null,
                quantity: item.count || [],
                tax_amount: (item.price || 0) * (0.05),
                add_on_ids: [],
                add_on_qtys: [],
            })),
            coupon_discount_amount: 0,
            coupon_discount_title: null,
            order_amount: Number(cartAmountData?.grandTotal),
            order_type: orderDetailData?.deliveryOption,
            delivery_address_id: OrderAddressId || 0,
            payment_method: 'stripe',
            order_note: '',
            coupon_code: null,
            delivery_time: selectedTimeSchedule?.time,
            delivery_date: moment(selectedTimeSchedule?.date).format('YYYY-MM-DD'),
            branch_id:orderDetailData?.selectedLocation?.id,
            distance: 0,
            
        };
        console.log("placeorderdata",placeOrderData)
        const headers = {
            Authorization: `Bearer ${token}`, // Use Bearer token format
            'Branch-Id': orderDetailData?.selectedLocation.id,
        }

        axios.post(COD_URL, placeOrderData, {
            headers
        })?.then((res) => {
            localStorage.removeItem('selectedItems');
            localStorage.removeItem('selectedDateTime');
            localStorage.removeItem('selectedId');
            navigate(`/paymentcopy/success/${res?.data?.order_id}`)
            
    })
        .catch((e) => {
            console.log('eerrr ::: ', e)
        })
    }
    

    useEffect(() => {
        placeMyOrder()
       
    }, [])

    return (
        <>
            <Container>
                <Row className="justify-content-center py-5">
                   
                <Col lg="10" className='d-flex justify-content-center'>
                       <div className='loadersus'>
                       </div>
                    </Col>
                  
                </Row>
            </Container>
        </>
    )
}
