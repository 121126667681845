import React, { useState, useEffect } from 'react';
import Header from '../../componant/Header';
import './Myprofile.css';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Offcanvas, Form, Button, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from 'react-router-dom';
import { Footer } from '../../componant/footer';
import { UPDATEPROFILE_URL, UPDATEPROFILEDATA_URL, DETETEACCOUNT_URL, SELECTED_BRANCH_ID, ORDERADDRESSADD_URL, GEOLOCATION_URL } from '../../componant/config/constant';
import axios from 'axios';
import { toast } from 'react-toastify';


const Myprofile = () => {
    const navigate = useNavigate();

    const [showProfileEdit, setShowProfileEdit] = useState(false);
    const [showPasswordEdit, setShowPasswordEdit] = useState(false);
    const [showMyAddress, setShowMyAddress] = useState(false);
    const [showMycredit, setShowMycredit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [EditFormData, setEditFormData] = useState({
        FirstName: '',
        LastName: '',
        Phone: '',
        Email: '',
        Address: ''
    })
    const [EditFormDataShow, setEditFormDataShow] = useState({});
    const [Addressdata, setAddressdata] = useState({
        Address: '',
        selectedOptions: 'Home'
    })
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [passwordFormData, setPasswordFormData] = useState({
        newPassword: '',
    });
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [addvalue, setaddvalue] = useState('');
    const [deliveryOption, setDeliveryOption] = useState('');
    useEffect(() => {
        const storedOrderData = localStorage.getItem('orderData');
        if (storedOrderData) {
            const parsedData = JSON.parse(storedOrderData);

            setDeliveryOption(parsedData.deliveryOption);
        }
    }, []);
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    console.log("tokenmyprofile::", token)
    const handlePasswordInputChange = (e) => {
        const { name, value } = e.target;
        setPasswordFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressdata(prevData => ({
            ...prevData,
            [name]: value,
        }));
        console.log("Updated Addressdata:", { ...Addressdata, [name]: value });
    }


    const handleAddValue = (Value) => {
        setaddvalue(Value);
        console.log("addvalue:::", Value)
    };

    const handleEditInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'select-one') {
            const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
            const selectedDate = selectedValues.join('-');
            setEditFormData((prevData) => ({
                ...prevData,
                [name]: selectedDate,

            }));
        } else {
            setEditFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            // console.log("Edirtdataaa::", EditFormData)
        }

    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(UPDATEPROFILE_URL, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Branch-Id': SELECTED_BRANCH_ID,
                    }
                });
                if (response.status === 200) {
                    // setProfileData({...response?.data})
                   
                    setEditFormData({
                        FirstName: response.data.f_name,
                        LastName: response.data.l_name,
                        Address: response.data.Address,
                        Phone: response.data.phone,
                        Birthdate: response.data.Birthdate,
                        Gender: response.data.Gender,
                        Email: response.data.email
                    });
                    setEditFormDataShow({ ...response.data, Email: response.data.email });
                    console.log("useinfo", response.data);
                }
                console.log("editform::", EditFormDataShow)
            } catch (error) {
                console.error('Error:', error);
                // navigate('/');
            }
        };
        fetchData();

    }, []);


    const handleProfileEditSubmit = (e) => {
        e.preventDefault();
        const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
        const token = isLoggedIn?.token;
        axios.post(UPDATEPROFILEDATA_URL, {
            f_name: EditFormData.FirstName,
            l_name: EditFormData.LastName,
            phone: EditFormData.Phone,
            email: EditFormData.Email,
            "_method": 'put'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Branch-Id': SELECTED_BRANCH_ID,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    alert("profile update successfuly")
                    setShowProfileEdit(false);
                    // window.location.reload();
                } else {
                    toast.error("Error updating profile: " + response.statusText);
                }
                console.log("Updateprofile::", response.data);
            })
            .catch(error => {
                toast.error("Error updating profile: " + error.message);
                console.error('Error:', error);
            });
    };
    const handlePasswordEditsubmit = (e) => {
        e.preventDefault();
        const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
        const token = isLoggedIn?.token;
        if (passwordFormData.newPassword !== passwordFormData.confirmPassword) {
            return;
        }
        axios.post(UPDATEPROFILEDATA_URL, {
            f_name: EditFormDataShow.f_name,
            l_name: EditFormDataShow.l_name,
            phone: EditFormDataShow.phone,
            email: EditFormDataShow.Email,
            password: passwordFormData.newPassword,
            "_method": 'put'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Branch-Id': SELECTED_BRANCH_ID,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    alert("password update successfuly")
                    handleClose()
                    window.location.reload();
                } else {
                    toast.error("Error updating profile: " + response.statusText);
                }
                console.log("Updateprofile::", response.data);
            })
            .catch(error => {
                toast.error("Error updating profile: " + error.message);
                console.error('Error:', error);
            });
    }
    const validateForm = (EditFormData) => {
        const errors = {};

        if (!EditFormData.FirstName.trim()) {
            errors.FirstName = 'First name is required';
        }
        if (!EditFormData.LastName.trim()) {
            errors.LastName = 'Last name is required';
        }
        if (!EditFormData.Address.trim()) {
            errors.Address = 'Address is required';
        }
        if (!EditFormData.Birthdate.trim()) {
            errors.Birthdate = 'Birthdate is required';
        }
        if (!EditFormData.Gender) {
            errors.Gender = 'Gender is required';
        }
        return errors;
    };
    const [formErrors, setFormErrors] = useState({});

    const handleProfileEdit = () => {
        setShowProfileEdit(true);
        setShowPasswordEdit(false);
    };
    const handleMyAddres = () => {
        setShowMyAddress(true);
        setShowMycredit(false);
    }
    const handleCredit = () => {
        setShowMyAddress(false);
        setShowMycredit(true);
    }
    const handleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const handlePasswordEdit = () => {
        setShowProfileEdit(false);
        setShowPasswordEdit(true);
    };

    const handleClose = () => {
        setShowProfileEdit(false);
        setShowPasswordEdit(false);
        // setShowMyAddress(false);
        // setShowMycredit(false);
    };
    const handleDeleteAccount = async () => {
        try {
            const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
            const response = await axios.delete(DETETEACCOUNT_URL, {
                headers: {
                    'Branch-Id': '1',
                    'Authorization': `Bearer ${isLoggedIn}`,
                }
            });
            console.log("hello::")
            console.log(response.data);
            if (response.status === 200) {
                localStorage.removeItem('login_Token');
                navigate('/');
            } else {
                console.error("Failed to delete profile");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Header latitude={latitude} longitude={longitude} />
            <div className='container-fluid '>
                <div className='div mt-2 p-3'>

                    <div className='row'>
                        {/* col-9 Section */}
                        <div className='col-8 border-end col-12 col-lg-8 col-xl-8 col-xxl-8'>
                            <div className='btndiv'>
                                <button className='btnuper  border border-secondary ' onClick={() => { navigate('/') }} >
                                    <span><MdOutlineKeyboardArrowLeft /></span>
                                    <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }} >Back to order</span>
                                </button>
                            </div>
                            <div>
                                <p className='profile-text'>My profile</p>
                            </div>

                            <div >
                                {/* First row */}
                                <div className='row '>
                                    <div className='col-6'>
                                        <div className='lables '>First name </div>
                                        <div className='sublable mb-2'>{EditFormDataShow.f_name}</div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='lables '>Last name</div>
                                        <div className='sublable mb-2'>{EditFormDataShow.l_name} </div>
                                    </div>
                                </div>

                                {/* Second row */}
                                <div className='row '>
                                    <div className='col-6'>
                                        <div className='lables '>Birthdate</div>
                                        <div className='sublable mb-2'>{EditFormData.Birthdate}</div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='lables '>Gender</div>
                                        <div className='sublable mb-2'>{EditFormData.Gender}</div>
                                    </div>
                                </div>

                                {/* Third row */}
                                <div className='row '>
                                    <div className='col-6'>
                                        <div className='lables '>Email</div>
                                        <div className='sublable mb-2'>{EditFormDataShow.Email}</div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='lables '>Phone</div>
                                        <div className='sublable mb-2'>{EditFormDataShow.phone}</div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='lables '>Address</div>
                                        <div className='sublable mb-2'></div>
                                    </div>
                                </div>

                                {/* Action row */}
                                <div className='row  mb-4 mt-2'>
                                    <div className='col-6 mt-1 col-sm-6 col-md-4 '>
                                        <div className='mt-2 text-decoration-underline text-warning' onClick={handleProfileEdit}>Edit my profile</div>
                                    </div>

                                    <div className='col-6 mt-1 col-sm-6 col-md-4 '>
                                        <div className='mt-2 text-decoration-underline ' onClick={handleDeleteModal}>Delete my profile</div>
                                    </div>
                                    <Modal closeButton show={showDeleteModal} onHide={handleDeleteModal} centered>

                                        <Modal.Body>
                                            <p className='fw-bold  text-center fs-5 mt-5'>Are you sure you want to delete your profile?</p>
                                            <p className='text-center'>This action cannot be undone.</p>

                                            < div className='d-flex justify-content-center gap-2'>
                                                <button className='border border-0  mb-5 text-decoration-underline ' style={{ background: 'none' }} onClick={handleDeleteModal}>
                                                    Cancel
                                                </button>
                                                <button className='border border-0 bg bg-warning  mb-5' style={{ borderRadius: '999px' }} onClick={handleDeleteAccount}>
                                                    Delete
                                                </button>
                                            </div>
                                        </Modal.Body>

                                    </Modal>
                                    <div className='col-6 mt-1 col-sm-6 col-md-4  mt-2 '>

                                        <button className='btnuper border border-secondary'>
                                            <span><MdOutlineKeyboardArrowLeft /></span>
                                            <span className='fw-normal' onClick={handlePasswordEdit} style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Edit my password   </span>
                                        </button>

                                    </div>


                                </div>
                            </div>
                        </div>

                        {/* col-3 Section */}
                        <div className='col-12 col-lg-4 col-xl-4 col-xxl-4 mt-3 mt-lg-0' style={{ padding: '20px' }}>
                            {deliveryOption === 'delivery' && (
                                <div className='col-12 col-xl-12 carddiv1 d-flex justify-content-between ' style={{ padding: '20px' }}>
                                    <div>
                                        <div className='jss583'>My addresses</div>
                                        <div className=' jss584' ><span className='text-warning'>0</span> available address</div>
                                        <div className=' jss584' > <span className='text-warning' onClick={() => { navigate('/locationaddress') }}>+</span>manage address</div>
                                    </div>

                                    <div className='Plus' style={{ background: '#fecd08', borderRadius: '999px', padding: '8px', width: '40px', height: '40px' }}>
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{ color: "#000000", marginInline: "5px" }}

                                            onClick={() => { navigate("/address") }}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='col-12 col-xl-12 carddiv2 d-flex justify-content-between ' style={{ padding: '20px' }}>
                                <div>
                                    <div className='jss583'>My order</div>
                                    {/* <div className=' jss584' ><span className='text-warning'>0</span> available cards</div> */}
                                </div>

                                <div className='Plus1' >
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ color: "#000000", marginInline: "5px" }}
                                        onClick={() => { navigate('/orderpage') }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Offcanvas */}
            <Offcanvas show={showProfileEdit || showPasswordEdit} onHide={handleClose} placement="end">
                {showProfileEdit ?
                    (<Offcanvas.Body className='canavasbody '>
                        <div className='offcanvas-upersec'>
                            <button className='btnuper border border-secondary' onClick={handleClose}>
                                <span><MdOutlineKeyboardArrowLeft /></span>
                                <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back  </span>
                            </button>
                            <p className='offcanvas-upersec-title'> Edit my profile</p>
                        </div>
                        <div className='offcanvas-upersec-body'>
                            <Form style={{ cursor: 'pointer' }}>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Firstname</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter your first name"
                                        name="FirstName"
                                        value={EditFormData.FirstName}
                                        onChange={handleEditInputChange} />
                                    {formErrors.FirstName && <Form.Text className="text-danger">{formErrors.FirstName}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Lastname</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter your last name"
                                        name="LastName"
                                        value={EditFormData.LastName}
                                        onChange={handleEditInputChange} />
                                    {formErrors.LastName && <Form.Text className="text-danger">{formErrors.LastName}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter your address"
                                        name='Address'
                                        value={addvalue}
                                        onChange={handleEditInputChange} />
                                    {formErrors.Address && <Form.Text className="text-danger">{formErrors.Address}</Form.Text>}
                                    <span className='text-decoration-underline text-warning'>Address not found?</span>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        name='Phone'
                                        placeholder="Enter your phone number"
                                        value={EditFormData.Phone}
                                        onChange={handleEditInputChange} />
                                    {formErrors.Phone && <Form.Text className="text-danger">{formErrors.Phone}</Form.Text>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>SelectAddress</Form.Label>
                                    <Form.Select aria-label="Default select example" name="SelectAddress" >
                                        <option>Select</option>
                                        <option value="Male">Home</option>
                                        <option value="Female">Workspace</option>
                                        <option value="Other">Other</option>
                                        {formErrors.Gender && <Form.Text className="text-danger">{formErrors.Gender}</Form.Text>}
                                    </Form.Select>
                                </Form.Group>

                            </Form>

                        </div>
                        <div className='bg bg-warning d-flex justify-content-center pt-2 ' style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%', cursor: 'pointer' }} onClick={handleProfileEditSubmit}>
                            Save
                        </div>
                    </Offcanvas.Body>
                    ) :

                    <Offcanvas.Body className='canavasbody'>
                        <div className='offcanvas-upersec'>
                            <button className='btnuper border border-secondary' onClick={handleClose}>
                                <span><MdOutlineKeyboardArrowLeft /></span>
                                <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back </span>
                            </button>
                            <p className='offcanvas-upersec-title'> Edit my password</p>
                        </div>
                        <div className='offcanvas-upersec-body'>
                            <Form>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control type="password"
                                        placeholder="Enter your new password"
                                        name="newPassword"
                                        value={passwordFormData.newPassword}
                                        onChange={handlePasswordInputChange}
                                        style={{ padding: '10px', cursor: 'pointer' }} />
                                    {formErrors.newPassword && <Form.Text className="text-danger">{formErrors.newPassword}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Confirm password</Form.Label>
                                    <Form.Control type="password"
                                        placeholder="Confirm your new password"
                                        name="confirmPassword"
                                        value={passwordFormData.confirmPassword}
                                        onChange={handlePasswordInputChange}
                                        style={{ padding: '10px', cursor: 'pointer' }} />
                                    {formErrors.confirmPassword && <Form.Text className="text-danger">{formErrors.confirmPassword}</Form.Text>}
                                </Form.Group>
                            </Form>
                        </div>

                        <div className='bg bg-warning d-flex justify-content-center pt-2'
                            style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%', cursor: 'pointer' }}
                            onClick={handlePasswordEditsubmit}>
                            Save
                        </div>
                    </Offcanvas.Body>
                }
            </Offcanvas>
            {/* <Offcanvas show={showMyAddress || showMycredit} onHide={handleClose} placement='end'>
                {
                    showMyAddress ? (<>
                        <Offcanvas.Body className='canavasbody'>
                            <div className='offcanvas-upersec'>
                                <button className='btnuper border border-secondary' onClick={handleClose}>
                                    <span><MdOutlineKeyboardArrowLeft /></span>
                                    <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back   </span>
                                </button>
                                <p className='offcanvas-upersec-title'>Add an address</p>
                            </div>
                            <div className=' border '></div>
                            <div className='offcanvas-upersec-body'>
                                <Form>
                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="" style={{ padding: '11px' }} name="Address" value={addvalue} onChange={handleAddressChange} />
                                        <span className=' text-decoration-underline text-warning' style={{ marginLeft: '70px' }}>Address not found ?</span>
                                    </Form.Group>
                                    <Form.Group className='d-flex justify-content-around'>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Home"
                                                name="selectedOptions"
                                                value="Home"
                                                checked={Addressdata.selectedOptions === "Home"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Home">Home</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Workspace"
                                                name="selectedOptions"
                                                value="Workspace"
                                                checked={Addressdata.selectedOptions === "Workspace"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Workspace">Workspace</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Other"
                                                name="selectedOptions"
                                                value="Other"
                                                checked={Addressdata.selectedOptions === "Other"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Other">Other</label>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Apt#</Form.Label>
                                        <Form.Control type="text" placeholder="" style={{ padding: '11px' }} />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className='bg bg-warning d-flex justify-content-center pt-2 ' 
                            style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%' }}  onClick={handleAddressSubmit && handleClose}>
                                Add
                            </div>
                        </Offcanvas.Body>
                    </>) : (<>
                        <Offcanvas.Body className='canavasbody'>
                            <div className='offcanvas-upersec'>
                                <button className='btnuper border border-secondary' onClick={handleClose}>
                                    <span><MdOutlineKeyboardArrowLeft /></span>
                                    <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back </span>
                                </button>
                                <p className='offcanvas-upersec-title'> Add  a credit card</p>
                            </div>
                            <div className=' border '></div>
                            <div className="cardinfo-text1">
                                <p className='text-center' >
                                    CREDIT CARD INFORMATIONS
                                </p>
                            </div>
                            <div className='credit-card'>
                                <Form.Group className="mb-2 p-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Cardholder's Name <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="" style={{ padding: '11px' }} />
                                </Form.Group>
                                <Form.Group className="mb-2 d-flex justify-content-between" controlId="exampleForm.ControlInput1">
                                    <div className='w-50 '>
                                        <Form.Label>Card Number <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="" style={{ padding: '11px', }} />
                                    </div>
                                    <div className='w-50 px-3'>
                                        <Form.Label>CVV/CVC <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="" style={{ padding: '11px' }} />
                                    </div>

                                </Form.Group>
                                <div className='p-2 w-50'>
                                    <Form.Label>Expiration date<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control type="number" placeholder="" style={{ padding: '11px' }} />
                                </div>
                                <div class="form-check m-2">
                                    <input className="form-check-input " type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label " for="flexCheckDefault">
                                        Do you want to save this card?
                                    </label>
                                </div>
                            </div>

                            <div className='bg bg-secondary-subtle d-flex justify-content-center pt-2 ' style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%' }}>
                                Add
                            </div>
                        </Offcanvas.Body>
                    </>)
                }
            </Offcanvas> */}
            {/* <Footer /> */}
        </>
    );
};

export default Myprofile;