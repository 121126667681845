import React, { useState, useEffect } from "react";
import "../Food-container/FoodConatiner.css";
import {
  faChevronRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdDeleteOutline } from "react-icons/md";
// import img1 from '../assets/38c4920a-0dc3-40a6-9b6d-17be1c7791fd.jpg'
// import img6 from '../assets/imgpsh_fullsize_anim (5).png'
import { Link, useNavigate } from "react-router-dom";
import { OptionModal } from "../optionModal";
// import { Modifymodel } from "../ModifyModal/modifymodal";
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { CATEGORY_URL, IMAGEBASE_URL, SUBCATEGORY_URL, SUGGESTIONSIMAGES_URL, SUGGESTIONS_URL, SELECTED_BRANCH_ID } from "../config/constant";
import { GrCart } from "react-icons/gr";
// import { toast } from 'react-toastify';
import HeaderBottam from "../HeaderBottom";
// import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { timeupdatereducer } from "./Redux/cartslice";

const FoodContainer = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [subFoodItems, setSubFoodItems] = useState([]);
  const [fetchedItems, setFetchedItems] = useState([]);
  const [suggestionItems, setSuggestionItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null)
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('')
  const [selectedId, setSelectedId] = useState(null)
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [UpdateData, setUpdateData] = useState(JSON.parse(localStorage.getItem("selectedItems")))
  const [selectedItems, setSelectedItems] = useState(UpdateData || []);
  const [selectedTime, setSelectedTime] = useState('');

  // const [currentTime,setcurrentTime] = useState(moment().format('h:mm A'))
  // console.log("currentTime",currentTime)



  const dateTime = useSelector((state) => state.timeupdatereducer);
  const dispatch = useDispatch();

  
  useEffect(() => {
    console.log('effecrt :: ', dateTime?.isTimeUpdate)
  }, [dateTime?.isTimeUpdate])


  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      const parsedDateTime = JSON.parse(storedDateTime);
      setSelectedTime(parsedDateTime.time);
    }
    const storedData = localStorage.getItem('selectedItems');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedItems(parsedData);
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [dateTime?.isTimeUpdate]);
  // console.log("sub::", selectedPrice)

  const handlesuggestionClick = (product) => {
    const newItem = {
      productId: product.id,
      itemName: product.name,
      price: product.price,
      count: 1,
      total: product.price, // Initialize total with the price of one item
    };

    const existingItemIndex = selectedItems.findIndex(item => item.productId === product.id);

    let updatedItems;

    if (existingItemIndex >= 0) {
      // If the item already exists, update its count and total
      updatedItems = selectedItems.map((item, index) => {
        if (index === existingItemIndex) {
          const updatedCount = item.count + 1;
          const updatedTotal = item.total ? item.total + product.price : item.price * updatedCount;
          return {
            ...item,
            count: updatedCount,
            total: updatedTotal,
          };
        }
        return item;
      });
    } else {
      // If the item does not exist, add the new item
      updatedItems = [...selectedItems, newItem];
    }

    setSelectedItems(updatedItems);
    localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
    console.log("updatedItems", updatedItems);
  };
  const handleItemClick = (index) => {

    setSubFoodItems((prevItems) =>
      prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, isOpen: !item.isOpen };
        } else if (item.isOpen) {

          return { ...item, isOpen: false };
        } else {
          return item;
        }
      })
    );
  };
  const removeItemFromSelected = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
    localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
  };

  const incrementCount = (index) => {
    setSelectedItems(prevItems => {
      const updatedItems = [...prevItems];
      let initialPrice = updatedItems[index].initialPrice;
      const count = updatedItems[index].count + 1;

      const addOnAmount = updatedItems[index]?.selectedAddOns?.filter((e, i) => updatedItems[index]?.selectedCards?.includes(i))?.reduce((sum, e) => sum + e?.price, 0)
      console.log('addOnAmount +++ :: ', addOnAmount)
      console.log("updatedItems", updatedItems)
      if (initialPrice === undefined || initialPrice === null) {
        initialPrice = updatedItems[index].price;
        updatedItems[index] = {
          ...updatedItems[index],
          initialPrice: initialPrice
        };
      }

      updatedItems[index] = {
        ...updatedItems[index],
        count: count,
        total: ((addOnAmount > 0 ? addOnAmount : 0) + initialPrice) * count
      };
      console.log("initialPrice", initialPrice)
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  };
  const decrementCount = (index) => {
    setSelectedItems(prevItems => {
      if (prevItems[index].count > 1) {
        const updatedItems = [...prevItems];
        const initialPrice = updatedItems[index].initialPrice; 
        const count = updatedItems[index].count - 1; 
        const addOnAmount = updatedItems[index]?.selectedAddOns?.filter((e, i) => updatedItems[index]?.selectedCards?.includes(i))?.reduce((sum, e) => sum + e?.price, 0)
        console.log('addOnAmount --- :: ', addOnAmount)
        updatedItems[index] = {
          ...updatedItems[index],
          count: count,
          total: ((addOnAmount > 0 ? addOnAmount : 0) + initialPrice) * count
        };
        localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
        return updatedItems;
      }
      return prevItems;
    });
  };

  const [currentSelectedProduct, setCurrentSelectedProduct] = useState({})

  const handleOptionClick = (item = {}) => {

    if (item?.id > 0) {

      setCurrentSelectedProduct({ ...item, count: 1, total: item?.price })
      let storedItemIds = localStorage.getItem('selectedItemIds');
      storedItemIds = storedItemIds ? JSON.parse(storedItemIds) : [];

      if (!storedItemIds.includes(item?.id)) {
        storedItemIds.push(item?.id);
      }
      localStorage.setItem('selectedItemIds', JSON.stringify(storedItemIds));

      setShowOptionModal(item?.add_ons?.length > 0)

      if (item?.add_ons?.length <= 0) {

        let cartProducts = localStorage.getItem('selectedItems') ? JSON.parse(localStorage.getItem('selectedItems')) : [];
        const oldData = cartProducts?.findIndex(e => { return e?.productId === item?.id || e?.selectedItemId === item?.id })
        console.log('ssssssssss :: ', oldData)
        const count = (oldData >= 0) ? cartProducts[oldData]?.count + 1 : 1
        const newItem = {
          productId: item?.id,
          itemName: item?.name,
          price: item?.price,
          count: count,
          total: item?.price * count
        }
        if (oldData >= 0) {
          cartProducts[oldData] = newItem
          console.log("old data", oldData)
        }
        else {
          cartProducts?.push(newItem)
        }
        localStorage.setItem('selectedItems', JSON.stringify(cartProducts))
        // window?.location?.reload()
        setSelectedItems([...cartProducts])
      }
    }
  };

  const handlePaymentClick = (onClick) => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems')) || [];
    console.log("storedItemIds", selectedItems);
    console.log("chchch::", token)
    if (!token) {
      alert("Please log in to proceed with payment.");
    } else if (selectedItems.length === 0) {
      alert("Your cart is empty. Please add items to proceed with payment.");
    } else {
      if (onClick === "confirm") {
        navigate("/cart");
      } else if (onClick === "pay") {
        navigate("/payment"); 
      } else {
        alert("Invalid action.");
      }
    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ...................categories.........................

  const fetchDataAndUpdateSubFoodItems = async (SELECTED_BRANCH_ID) => {
    try {
      const response = await axios.get(
        CATEGORY_URL + `/${SELECTED_BRANCH_ID}`
      );
      console.log("API response:", response.data);
      const categoriesData = response.data.map((category) => ({
        id: category.id,
        name: category.name,
        image: category.image,
        subItems: [],
        isOpen: false,
      }));
      setSubFoodItems(categoriesData);
      setLoading(false)
      console.log("categoriesData", categoriesData)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(true)
    }
  };
  useEffect(() => {

    fetchDataAndUpdateSubFoodItems(SELECTED_BRANCH_ID);
  }, [SELECTED_BRANCH_ID])
  // ...............sub categories........................
  const fetchData1 = async (category_Id) => {
    try {
      const response = await axios.get(SUBCATEGORY_URL +`${category_Id}`, {
        headers: {
          "Branch-Id": SELECTED_BRANCH_ID,
        },
      });
      console.log("shhsw::", response.data);
      const subCategoryData = response.data.map((subItem) => ({
        name: subItem.name,
        description: subItem.description,
        price: subItem.price,
        image: subItem.image,
        id: subItem.id,
        add_ons: subItem.add_ons,
      }));

      setSubFoodItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === category_Id) {
            return {
              ...item,
              subItems: subCategoryData,
              isOpen: true,
            };
          }
          return item;
        })
      );
      console.log("subFoodItems", subCategoryData);
      setFetchedItems((prev) => [...prev, category_Id]); // Add the category ID to the fetched items list
    } catch (error) {
      console.error("Error fetching sub-category data for category ID", error);
    }
  };

  useEffect(() => {
    subFoodItems.forEach((item) => {
      if (item.isOpen && item.subItems.length === 0 && !fetchedItems.includes(item.id)) {
        fetchData1(item.id);
      }
    });
  }, [subFoodItems, fetchedItems]); 
  // ................suggestion................................
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          SUGGESTIONS_URL,
          {
            headers: {
              "Branch-Id": SELECTED_BRANCH_ID,
            },
          }
        );
        setSuggestionItems(response.data.products);
      } catch (error) {
        console.error("Error fetching suggestion items:", error);
      }
    };
    fetchData2();
  }, []);
  const calculateSubtotal = () => {
    const subtotal = selectedItems.reduce((accumulator, currentItem) => {
      if (typeof currentItem.total === 'number') {
        return accumulator + currentItem.total;
      }
      else if (typeof currentItem.price === 'number' && typeof currentItem.count === 'number') {
        return accumulator + (currentItem.price * currentItem.count);
      }
      // If neither, return the accumulator unchanged
      return accumulator;
    }, 0);

    // console.log("selectedItems:", selectedItems);
    // console.log("Subtotal:", subtotal);
    return subtotal;
  };
  const calculateGST = () => {
    let gst = calculateSubtotal() * 0.05;
    return gst;
  };

  // const calculateQST = () => {
  //   let qst = calculateSubtotal() * 0.09975; 
  //   return qst;
  // };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const gst = calculateGST();
    const total = subtotal + gst;
    return total;
  };
  const [cartItemsCount, setCartItemsCount] = useState([]);

  useEffect(() => {
    const storedata = localStorage.getItem("selectedItems");
    if (storedata) {
      const storedata = localStorage.getItem("selectedItems");
      setCartItemsCount(JSON.parse(storedata))
    }
  }, [])


  const editCartCurrentProduct = (item, index) => {
    console.log('item :: ', item)
    console.log('index :: ', index)

    item.name = item?.itemName
    item.add_ons = item?.selectedAddOns
    item.indexCode = index

    setCurrentSelectedProduct({ ...item })
    setShowOptionModal(true)
  }

  console.log('setCurrentSelectedProduct :: ', currentSelectedProduct)

  return (
    <>
      <HeaderBottam />
      {loading ? (<div class="loader-line" style={{ width: "100%" }}></div>) : (
        <div className="food-container">

          <div className="row">
            <div className="col-8 col-xxl-8 col-xl-8 col-md-8 food-container-box-1">
              {subFoodItems.map((item, index) => (
                <div className="sub-food-container-box-1" key={item.id}>
                  <div
                    className="sub-food-container-sub-box-1-duplicate"
                    onClick={() => handleItemClick(index)}
                    style={{
                      backgroundColor: item.isOpen ? "#ffffff" : "#efefef",
                      color: item.isOpen ? "#fecd08" : "rgb(51, 51, 51)",
                    }}
                  >
                    <div className="sub-food-container-sub-box-text-duplicate">
                      <div className="textname" >{item.name}</div>
                      <div className={item.isOpen ? "caret up" : "caret down"}></div>
                    </div>
                  </div>
                  {item.isOpen && (
                    <>
                      <div className="row sub-food-container-sub-box-2" key={item.id}>
                        <div className="sub-food-container-sub-sub-box-1-img col-6 mt-3  ">
                          <img src={`${IMAGEBASE_URL}${item.image}`} alt={item.name} style={{ height: "160px" }} className="itemimg" />
                        </div>
                        {item.subItems && item.subItems.length > 0 ? (
                          item.subItems.map((subItem, subIndex) => (
                            <div className="sub-food-container-sub-sub-box-1 col-6" key={subItem.id}>
                              <div className="sub-food-container-sub-sub-box-1-con col-12">
                                <div className="sub-food-container-sub-sub-box-1-con-p col-12">
                                  <div className="flex">
                                    <div>
                                      <div className="flex-text1">
                                        {subItem.name}
                                      </div>
                                      <div className="flex-text2">
                                        {subItem.description}
                                      </div>
                                    </div>
                                    <div className="amount">${subItem.price}</div>
                                  </div>
                                  <div className="plus cursor" onClick={() => handleOptionClick(subItem)}>
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ color: "#000000" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-6 mt-3">
                            <p className="fw-semibold text-warning fs-4 ">No items available.</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
              <div className="cartbtn row d-flex">
                {selectedItems.length === 0 ? (
                  <>
                    <button className="btn border  bg-white rounded-0 col-6" style={{ marginTop: "50px" }}>
                      <div className="d-flex justify-content-around">
                        <div className="d-inline">
                          <GrCart style={{ fontSize: '25px', cursor: 'pointer' }} />
                          <span className="fw-bolder"> 0</span>
                        </div>
                        <div className="d-inline">
                          <span className="text-warning">${calculateTotal()}</span>
                        </div>
                      </div>
                    </button>
                    <button className="btn bg-warning rounded-0 col-6" style={{ marginTop: "50px", color: "#093455" }} onClick={() => handlePaymentClick("Confirm")}  >
                      Confirm<span ><FontAwesomeIcon size="sm" icon={faChevronRight} /></span>
                    </button>
                  </>
                ) : (
                  <>
                    <button className="btn border rounded-0 col-6" style={{ marginTop: "50px" }}>
                      <div className="d-flex justify-content-around">
                        <div className="d-inline">
                          <GrCart style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => { navigate('/cart') }} />
                          <span className="fw-bolder">{cartItemsCount.length} Item{cartItemsCount.length !== 1 ? 's' : ''}</span>
                        </div>
                        <div className="d-inline">
                          <span className="text-warning">${Number(calculateTotal()).toFixed(2)}</span>
                        </div>
                      </div>
                    </button>
                    <button className="btn bg-warning rounded-0 col-6" style={{ marginTop: "50px", color: "#093455" }} onClick={() => { navigate('/cart') }}>
                      Confirm
                      <span > <FontAwesomeIcon size="sm" icon={faChevronRight} /></span>
                    </button>
                  </>
                )}
              </div>
            </div>
            {/* ................................box2.......................................................  */}
            <div className="col-4 col-xxl-4 col-xl-4 col-md-4 food-container-box-2">
              <div className="food-container-box-2-width">
                <div className="food-container-box-2-flex" >
                  <div className="food-container-box-2-cart-1-div" >

                    <div className="food-container-box-2-cart-1-text1">
                      ORDER READY FOR
                    </div>
                    <div className="food-container-box-2-cart-1-text2">
                      {selectedTime}
                    </div>
                    <div className="food-container-box-2-cart-1-text3">
                      (in 9 hours)
                    </div>
                  </div>
                  <div className="food-container-box-2-cart-2-div">
                    <div className="food-container-box-2-cart-2-div-1">
                      <div className="food-container-box-2-cart-2-div-1-flex">
                        {selectedItems.length === 0 ? (<div className="h5 text-center" style={{ color: "#a5a5a5", padding: "30px" }}>Your order is empty</div>)
                          :
                          (selectedItems.map((item, index) => (
                            <>
                              <div key={index} className="food-container-box-2-cart-2-div-1-flex-flex2">
                                <div className="food-container-box-2-cart-2-div-1-flex-flex2-flex3">
                                  <div className="food-container-box-2-cart-2-div-1-flex-flex2-flex3-flex-4">
                                    <div className="food-container-box-2-cart-2-div-1-text">
                                      {item.itemName}
                                    </div>
                                    <div className="food-container-box-2-cart-2-div-1-amount">

                                      {/* ${item.price.toFixed(2)} */}
                                      ${Number(item.total ? item.total : item.price).toFixed(2)}
                                    </div>
                                  </div>
                                  <div className="food-container-box-2-cart-2-div-1-text2">
                                    <p>{item.selectedCardText}</p>
                                  </div>
                                  {/* <div className="food-container-box-2-cart-2-div-1-text3" onClick={() => handleModifyClick(selectedItems)} style={{ marginTop: "-15px" }}>
                                    Modify
                                  </div> */}
                                  {
                                    item?.selectedAddOns?.length > 0 && (
                                      <div className="food-container-box-2-cart-2-div-1-text3"
                                        onClick={() => editCartCurrentProduct(item, index)} style={{ marginTop: "-15px" }}>
                                        Modify
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              <div className="food-container-box-2-cart-2-div-2-flex-flex2">
                                <div className="food-container-box-2-cart-2-div-2-flex-flex2-flex3">
                                  <button className="food-container-box-2-cart-2-div-2-button1" onClick={() => decrementCount(index)}>
                                    <FontAwesomeIcon
                                      icon={faMinus}
                                      style={{ color: "#050505" }}
                                    />
                                  </button>
                                  <div>{item.count}</div>
                                  <button className="food-container-box-2-cart-2-div-2-button2" onClick={() => incrementCount(index)}>
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ color: "#050505" }}
                                    />
                                  </button>
                                </div>
                                <button className="food-container-box-2-cart-2-div-2-button3">
                                  <MdDeleteOutline
                                    style={{ color: '#000000', fontSize: '23px', background: '#efefef', borderRadius: '50px' }}
                                    onClick={() => removeItemFromSelected(index)}
                                    className="delete"
                                  />
                                </button>
                              </div>
                            </>
                          )))}
                      </div>
                    </div>
                    {selectedItems.length > 0 && (
                      <>
                        <div className="food-container-box-2-cart-2-div-2">
                          <div className="food-container-box-2-cart-2-div-2-sub-div">
                            <div className="food-container-box-2-cart-2-div-2-sub-div-text1">
                              PROMOTIONS
                            </div>
                            <button className="food-container-box-2-cart-2-div-2-sub-div-button">
                              <div className="food-container-box-2-cart-2-div-2-sub-div-text2" onClick={handleShow}>
                                Add a promotion
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="food-container-box-2-cart-2-div-3 suggestion">
                          <div className="food-container-box-2-cart-2-div-3-sub-div">
                            <div className="food-container-box-2-cart-2-div-3-sub-div-text">
                              SUGGESTIONS
                            </div>
                            {suggestionItems.length > 0 && (
                              suggestionItems.map((product, index) => (
                                <div key={index} className={`food-container-box-2-cart-2-div-3-sub-div-button-con ${product.className}`}>
                                  <button className="food-container-box-2-cart-2-div-3-sub-div-buttons">
                                    <img
                                      className="food-container-box-2-cart-2-div-3-sub-div-buttons-img sugimg"
                                      src={`${SUGGESTIONSIMAGES_URL}${product.image}`}
                                    />
                                    <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-flex">
                                      <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-text">
                                        <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-amount">
                                          ${product.price || product.total}
                                        </div>
                                        <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-text-1" >
                                          {product.name}
                                        </div>
                                      </div>
                                      <div className="plus" onClick={() => handlesuggestionClick(product)}>
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          style={{ color: "#000000" }}
                                        />
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              ))
                            )}

                          </div>
                        </div>

                        <div className="food-container-box-2-cart-2-div-4 ">

                          <div className="food-container-box-2-cart-2-div-4-sub-div">
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1">
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text1">
                                SUB-TOTAL
                              </div>
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text2">
                                <p>${Number(calculateSubtotal()).toFixed(2)}</p>
                              </div>
                            </div>
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1">
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text1">
                                GST
                              </div>
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text2">
                                ${calculateGST().toFixed(2)}
                              </div>
                            </div>
                            {/* <div className="food-container-box-2-cart-2-div-4-sub-div-1">
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text1">
                                QST
                              </div>
                              <div className="food-container-box-2-cart-2-div-4-sub-div-1-text2">
                                ${calculateQST().toFixed(2)}
                              </div>
                            </div> */}
                          </div>

                        </div>
                      </>
                    )}
                  </div>
                  <div className="food-container-box-2-cart-3-div">
                    <div className="food-container-box-2-cart-3-div-text1">
                      TOTAL
                    </div>
                    <div className="food-container-box-2-cart-3-div-text2">
                      ${calculateTotal().toFixed(2)}
                    </div>
                  </div>
                  <div className="food-container-box-2-cart-5-div">
                    <button className="pay-button"   onClick={() => handlePaymentClick("pay")}>
                      <div className="pay-button-text d-flex " style={{ color: 'rgb(9, 52, 85)' }} onClick={() => handlePaymentClick("pay")}>Pay<span > <FontAwesomeIcon style={{ fontSize: "15px" }}
                        icon={faChevronRight}
                      /></span> </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Body className="p-0">
              <div className='offcanvas-upersec'>
                <button className='btnuper border border-secondary' onClick={handleClose}>
                  <span><MdOutlineKeyboardArrowLeft /></span>
                  <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back   </span>
                </button>
                <p className='offcanvas-upersec-title'>Add a promotion</p>
              </div>
              <div className="border p-4 d-flex flex-column text-center align-items-center" style={{ width: "100%", backgroundColor: "#F1F1F1" }}>
                <p className="h5 fw-bolder">Got a code?</p>
                <div class="input-group mb-3  border border-secondary-subtle bg-white" style={{ borderRadius: "7px" }}>
                  <span class="input-group-text bg-white" id="basic-addon1" style={{ border: "none", color: "gray" }}>#</span>
                  <input type="text" style={{ width: "80%", border: "none" }} className="p-1 focus " />
                </div>
              </div>
              <div className='bg bg-warning d-flex justify-content-center pt-2 ' style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%' }}>
                Add
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <OptionModal
            show={showOptionModal}
            onHide={() => setShowOptionModal(false)}
            itemName={selectedItemName}
            price1={selectedPrice}
            selectedItem={selectedItem}
            selectedItemId={selectedId}
            selectedAddOns={selectedAddOns}
            currentSelectedProduct={currentSelectedProduct}


          // count1={count}
          />
          {/* <Modifymodel
            show={showmodifyModel}
            onHide={() => setShowmodifyModel(false)}
            // itemName={selectedItemName}
            // price1={selectedPrice}
            // selectedItem={selectedItem}
            // selectedItemId={selectedId}
            // selectedAddOns={selectedAddOns}
            selectedItems={selectedItems}
          /> */}

        </div>
      )}

    </>
  );
};

export default FoodContainer;