import React, { useState, useEffect } from "react";
import {
  faCartShopping,
  faChevronRight,
  faMinus,
  faPlus,
  faTrash,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdDeleteOutline, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import img1 from '../../componant/assets/38c4920a-0dc3-40a6-9b6d-17be1c7791fd.jpg'
import img6 from '../../componant/assets/imgpsh_fullsize_anim (5).png'
import { Link, useNavigate } from "react-router-dom";
import { OptionModal } from "../../componant/optionModal";
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";
import { CATEGORY_URL, IMAGEBASE_URL, SUBCATEGORY_URL, SUGGESTIONSIMAGES_URL, SUGGESTIONS_URL, SELECTED_BRANCH_ID } from '../../componant/config/constant';
import { GrCart } from "react-icons/gr";
import { toast } from 'react-toastify';
import './cart.css'

const Cart = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [subFoodItems, setSubFoodItems] = useState([]);
  const [suggestionItems, setSuggestionItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null)
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('')
  const [selectedId, setSelectedId] = useState(null)
  const [UpdateData, setUpdateData] = useState(JSON.parse(localStorage.getItem("selectedItems")))
  const [selectedItems, setSelectedItems] = useState(UpdateData || []);
  const [loading, setLoading] = useState(true);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  useEffect(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      const parsedDateTime = JSON.parse(storedDateTime);
      setSelectedTime(parsedDateTime.time);
    }
    const storedData = localStorage.getItem('selectedItems');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedItems(parsedData);
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  // console.log("sub::", selectedPrice)

  const handlesuggestionClick = (product) => {
    const newItem = {
      productId: product.id,
      itemName: product.name,
      price: product.price,
      count: 1,
    };
    setSelectedItems([...selectedItems, newItem]);
    const updatedItems = [...selectedItems, newItem];
    localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
  };
  const handleItemClick = (index) => {

    setSubFoodItems((prevItems) =>
      prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, isOpen: !item.isOpen };
        } else if (item.isOpen) {

          return { ...item, isOpen: false };
        } else {
          return item;
        }
      })
    );
  };
  const removeItemFromSelected = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
    localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
  };

  const incrementCount = (index) => {
    setSelectedItems(prevItems => {
      const updatedItems = [...prevItems];
      let initialPrice = updatedItems[index].initialPrice;
      const count = updatedItems[index].count + 1;

      const addOnAmount = updatedItems[index]?.selectedAddOns?.filter((e, i) => updatedItems[index]?.selectedCards?.includes(i))?.reduce((sum, e) => sum + e?.price, 0)
      console.log('addOnAmount +++ :: ', addOnAmount)
      console.log("updatedItems", updatedItems)
      if (initialPrice === undefined || initialPrice === null) {
        initialPrice = updatedItems[index].price;
        updatedItems[index] = {
          ...updatedItems[index],
          initialPrice: initialPrice
        };
      }

      updatedItems[index] = {
        ...updatedItems[index],
        count: count,
        total: ((addOnAmount > 0 ? addOnAmount : 0) + initialPrice) * count
      };
      console.log("initialPrice", initialPrice)
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  };
  const decrementCount = (index) => {
    setSelectedItems(prevItems => {
      if (prevItems[index].count > 1) {
        const updatedItems = [...prevItems];
        const initialPrice = updatedItems[index].initialPrice; // Get the initial price of the item
        const count = updatedItems[index].count - 1; // Decrement the count
        const addOnAmount = updatedItems[index]?.selectedAddOns?.filter((e, i) => updatedItems[index]?.selectedCards?.includes(i))?.reduce((sum, e) => sum + e?.price, 0)
        console.log('addOnAmount --- :: ', addOnAmount)
        updatedItems[index] = {
          ...updatedItems[index],
          count: count,
          total: ((addOnAmount > 0 ? addOnAmount : 0) + initialPrice) * count
        };
        localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
        return updatedItems;
      }
      return prevItems;
    });
  };

  const [currentSelectedProduct, setCurrentSelectedProduct] = useState({})

  const handleOptionClick = (item = {}) => {

    if (item?.id > 0) {

      setCurrentSelectedProduct({ ...item, count: 1, total: item?.price })

      let storedItemIds = localStorage.getItem('selectedItemIds');
      storedItemIds = storedItemIds ? JSON.parse(storedItemIds) : [];

      if (!storedItemIds.includes(item?.id)) {
        storedItemIds.push(item?.id);
      }
      localStorage.setItem('selectedItemIds', JSON.stringify(storedItemIds));

      setShowOptionModal(item?.add_ons?.length > 0)

      if (item?.add_ons?.length <= 0) {

        let cartProducts = localStorage.getItem('selectedItems') ? JSON.parse(localStorage.getItem('selectedItems')) : [];
        const oldData = cartProducts?.findIndex(e => { return e?.productId === item?.id || e?.selectedItemId === item?.id })
        console.log('ssssssssss :: ', oldData)
        // return
        const count = (oldData >= 0) ? cartProducts[oldData]?.count + 1 : 1

        const newItem = {
          productId: item?.id,
          itemName: item?.name,
          price: item?.price,
          count: count,
          total: item?.price * count
        }
        if (oldData >= 0) {
          cartProducts[oldData] = newItem
        } else {
          cartProducts?.push(newItem)
        }
        localStorage.setItem('selectedItems', JSON.stringify(cartProducts))
        window?.location?.reload()
      }
    }

  };

  const handlePaymentClick = () => {
    let selectedItems = JSON.parse(localStorage.getItem('selectedItems')) || [];
    console.log("storedItemIds", selectedItems);
    if (selectedItems.length === 0) {
      console.log("No items selected, please add items");
      // You might want to display an error message or take some other action here
    } else {
      navigate("/payment"); // Assuming you're using React Router for navigation
    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ...................categories.........................

  const fetchDataAndUpdateSubFoodItems = async (SELECTED_BRANCH_ID) => {
    try {
      const response = await axios.get(
        CATEGORY_URL + `/${SELECTED_BRANCH_ID}`
      );
      console.log("API response:", response.data);
      const categoriesData = response.data.map((category) => ({
        id: category.id,
        name: category.name,
        image: category.image,
        subItems: [],
        isOpen: false,
      }));
      setSubFoodItems(categoriesData);
      setLoading(false)
      console.log("categoriesData", categoriesData)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(true)
    }
  };
  useEffect(() => {

    fetchDataAndUpdateSubFoodItems(SELECTED_BRANCH_ID);
  }, [SELECTED_BRANCH_ID])
  // ...............sub categories........................
  useEffect(() => {
    const fetchData1 = async (category_Id) => {
      try {
        const response = await axios.get(
          SUBCATEGORY_URL + `${category_Id}`,
          {
            headers: {
              "Branch-Id": SELECTED_BRANCH_ID,
            },
          }
        );
        console.log("shhsw::", response.data)
        const subCategoryData = response.data.map((subItem) => ({
          name: subItem.name,
          description: subItem.description,
          price: subItem.price,
          image: subItem.image,
          id: subItem.id,
          add_ons: subItem.add_ons
        }));

        setSubFoodItems((prevItems) =>
          prevItems.map((item) => {
            if (item.id === category_Id) {
              return {
                ...item,
                subItems: subCategoryData,
                isOpen: true,

              };
            }
            return item;
          })
        );
        console.log("subFoodItems", subCategoryData)
      } catch (error) {
        console.error("Error fetching sub-category data for category ID", error);
      }
    };
    subFoodItems.forEach((item) => {
      if (item.isOpen && item.subItems.length === 0) {
        fetchData1(item.id);
      }
    }
    );

  }, [subFoodItems]);
  // ................suggestion................................
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          SUGGESTIONS_URL,
          {
            headers: {
              "Branch-Id": SELECTED_BRANCH_ID,
            },
          }
        );
        setSuggestionItems(response.data.products);
      } catch (error) {
        console.error("Error fetching suggestion items:", error);
      }
    };
    fetchData2();
  }, []);

  const calculateSubtotal = () => {
    const subtotal = selectedItems.reduce((accumulator, currentItem) => {
      if ('total' in currentItem) {
        return accumulator + currentItem.total;
      } else if ('price' in currentItem && 'count' in currentItem) {
        return accumulator + (currentItem.price * currentItem.count);
      }
      return accumulator; 
    }, 0);
    console.log("selectedItems:", selectedItems);
    console.log("Subtotal:", subtotal);
    return subtotal;
  }
  const calculateGST = () => {
    let gst = calculateSubtotal() * 0.05;
    return gst;
  };

  // const calculateQST = () => {
  //   let qst = calculateSubtotal() * 0.09975; 
  //   return qst;
  // };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const gst = calculateGST();
    // const qst = calculateQST();
    const total = subtotal + gst;
    // + qst;
    return total;
  };
  const [cartItemsCount, setCartItemsCount] = useState([]);

  useEffect(() => {
    const storedata = localStorage.getItem("selectedItems");
    if (storedata) {
      const storedata = localStorage.getItem("selectedItems");
      setCartItemsCount(JSON.parse(storedata))
    }
  }, [])


  const editCartCurrentProduct = (item, index) => {
    console.log('item :: ', item)
    console.log('index :: ', index)

    item.name = item?.itemName
    item.add_ons = item?.selectedAddOns
    item.indexCode = index

    setCurrentSelectedProduct({ ...item })
    setShowOptionModal(true)
  }

  console.log('setCurrentSelectedProduct :: ', currentSelectedProduct)

  return (
    <>
      {loading ? (<div class="loader-line" style={{ width: "100%" }}></div>) : (
        <div className="food-containercart p-0">
          {/* ................................box2.......................................................  */}

            <div className="food-container-box-2-width">
              <div className="food-container-box-2-flex" >
                <div className="food-container-box-2-cart-1-div" >

                  <div className="food-container-box-2-cart-1-text1">
                    ORDER READY FOR
                  </div>
                  <div className="food-container-box-2-cart-1-text2">
                    {selectedTime}
                  </div>
                  <div className="food-container-box-2-cart-1-text3">
                    (in 9 hours)
                  </div>
                </div>
                <div className="food-container-box-2-cart-2-div">
                  <div className="food-container-box-2-cart-2-div-1">
                    <div className="food-container-box-2-cart-2-div-1-flex">
                      {selectedItems.length === 0 ? (<div className="h5 text-center" style={{ color: "#a5a5a5", padding: "30px" }}>Your order is empty</div>)
                        :
                        (selectedItems.map((item, index) => (
                          <>
                            <div key={index} className="food-container-box-2-cart-2-div-1-flex-flex2">
                              <div className="food-container-box-2-cart-2-div-1-flex-flex2-flex3">
                                <div className="food-container-box-2-cart-2-div-1-flex-flex2-flex3-flex-4">
                                  <div className="food-container-box-2-cart-2-div-1-text">
                                    {item.itemName}
                                  </div>
                                  <div className="food-container-box-2-cart-2-div-1-amount">

                                    {/* ${item.price.toFixed(2)} */}
                                    ${(item.total ? item.total.toFixed(2) : item.price)}
                                  </div>
                                </div>
                                <div className="food-container-box-2-cart-2-div-1-text2">
                                  <p>{item.selectedCardText}</p>
                                </div>
                                {/* <div className="food-container-box-2-cart-2-div-1-text3" onClick={() => handleModifyClick(selectedItems)} style={{ marginTop: "-15px" }}>
                                    Modify
                                  </div> */}
                                {
                                  item?.selectedAddOns?.length > 0 && (
                                    <div className="food-container-box-2-cart-2-div-1-text3" onClick={() => editCartCurrentProduct(item, index)} style={{ marginTop: "-15px" }}>
                                      Modify
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                            <div className="food-container-box-2-cart-2-div-2-flex-flex2">
                              <div className="food-container-box-2-cart-2-div-2-flex-flex2-flex3">
                                <button className="food-container-box-2-cart-2-div-2-button1" onClick={() => decrementCount(index)}>
                                  <FontAwesomeIcon
                                    icon={faMinus}
                                    style={{ color: "#050505" }}
                                  />
                                </button>
                                <div>{item.count}</div>
                                <button className="food-container-box-2-cart-2-div-2-button2" onClick={() => incrementCount(index)}>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ color: "#050505" }}
                                  />
                                </button>
                              </div>
                              <button className="food-container-box-2-cart-2-div-2-button3">
                                <MdDeleteOutline
                                  style={{ color: '#000000', fontSize: '23px', background: '#efefef', borderRadius: '50px' }}
                                  onClick={() => removeItemFromSelected(index)}
                                  className="delete"
                                />
                              </button>
                            </div>
                          </>
                        )))}
                    </div>
                  </div>
                  {selectedItems.length > 0 && (
                    <>
                      <div className="food-container-box-2-cart-2-div-2">
                        <div className="food-container-box-2-cart-2-div-2-sub-div">
                          <div className="food-container-box-2-cart-2-div-2-sub-div-text1">
                            PROMOTIONS
                          </div>
                          <button className="food-container-box-2-cart-2-div-2-sub-div-button">
                            <div className="food-container-box-2-cart-2-div-2-sub-div-text2" onClick={handleShow}>
                              Add a promotion
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="food-container-box-2-cart-2-div-3 suggestion">
                        <div className="food-container-box-2-cart-2-div-3-sub-div">
                          <div className="food-container-box-2-cart-2-div-3-sub-div-text">
                            SUGGESTIONS
                          </div>
                          {suggestionItems.length > 0 && (
                            suggestionItems.map((product, index) => (
                              <div key={index} className={`food-container-box-2-cart-2-div-3-sub-div-button-con ${product.className}`}>
                                <button className="food-container-box-2-cart-2-div-3-sub-div-buttons">
                                  <img
                                    className="food-container-box-2-cart-2-div-3-sub-div-buttons-img sugimg"
                                    src={`${SUGGESTIONSIMAGES_URL}${product.image}`}
                                  />
                                  <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-flex">
                                    <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-text">
                                      <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-amount">
                                      ` ${parseFloat(product.price).toFixed(2)}`
                                      </div>
                                      <div className="food-container-box-2-cart-2-div-3-sub-div-buttons-text-1">
                                        {product.name}
                                      </div>
                                    </div>
                                    <div className="cartplus" onClick={() => handlesuggestionClick(product)}>
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ color: "#000000" }}
                                      />
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ))
                          )}

                        </div>
                      </div>

                      <div className="food-container-box-2-cart-2-div-4 ">

                        <div className="food-container-box-2-cart-2-div-4-sub-div">
                          <div className="food-container-box-2-cart-2-div-4-sub-div-1">
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1-text1">
                              SUB-TOTAL
                            </div>
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1-text2">
                              <p>  ${Number(calculateSubtotal()).toFixed(2)}</p>
                            </div>
                          </div>
                          <div className="food-container-box-2-cart-2-div-4-sub-div-1">
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1-text1">
                              GST
                            </div>
                            <div className="food-container-box-2-cart-2-div-4-sub-div-1-text2">
                            ${Number(calculateGST()).toFixed(2)}
                            </div>
                          </div>
                        </div>

                      </div>
                    </>
                  )}
                </div>
                <div className="food-container-box-2-cart-3-div">
                  <div className="food-container-box-2-cart-3-div-text1">
                    TOTAL
                  </div>
                  
                  <div className="food-container-box-2-cart-3-div-text2">
                  ${Number(calculateTotal()).toFixed(2)}
                  </div>
                </div>
                 <div className="food-container-box-2-cart-5-div row">
                 <button className="btn border bg-white col-6 py-3" style={{borderRadius:'0 0 20px 20px'}} >
                    <div className="text-center" style={{ color: 'rgb(9, 52, 85)'}} onClick={() => { navigate("/") }}><span><FontAwesomeIcon
                      size="md"
                      icon={faChevronLeft}
                      className="me-2"
                    /></span>Edit</div>
                  </button>
          
                  <button className="btn bg-warning col-6 py-3 "style={{borderRadius:'0 0 20px 20px'}} >
                    <div className="text-center" style={{ color: 'rgb(9, 52, 85)' }} onClick={() => { navigate("/payment") }}>Pay<span><FontAwesomeIcon
                      size="md"
                      icon={faChevronRight}
                      className="ms-2"
                    /></span> </div>
                  </button>
                </div>
              </div>
            </div>

          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Body className="p-0">
              <div className='offcanvas-upersec'>
                <button className='btnuper border border-secondary' onClick={handleClose}>
                  <span><MdOutlineKeyboardArrowLeft /></span>
                  <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }}>Back   </span>
                </button>
                <p className='offcanvas-upersec-title'>Add a promotion</p>
              </div>
              <div className="border p-4 d-flex flex-column text-center align-items-center" style={{ width: "100%", backgroundColor: "#F1F1F1" }}>
                <p className="h5 fw-bolder">Got a code?</p>
                <div class="input-group mb-3  border border-secondary-subtle bg-white" style={{ borderRadius: "7px" }}>
                  <span class="input-group-text bg-white" id="basic-addon1" style={{ border: "none", color: "gray" }}>#</span>
                  <input type="text" style={{ width: "80%", border: "none" }} className="p-1 focus " />
                </div>
              </div>
              <div className='bg bg-warning d-flex justify-content-center pt-2 ' style={{ height: '6vh', position: 'absolute', bottom: '0px', width: '100%' }}>
                Add
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <OptionModal
            show={showOptionModal}
            onHide={() => setShowOptionModal(false)}
            itemName={selectedItemName}
            price1={selectedPrice}
            selectedItem={selectedItem}
            selectedItemId={selectedId}
            selectedAddOns={selectedAddOns}
            currentSelectedProduct={currentSelectedProduct}
          />


        </div>
      )}

    </>
  );
};

export default Cart;