import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { faChevronRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { OrderAddress } from "../OrderAdress";
import { LoginRegister } from "../LoginRegister";
// import Order from "../Order";
import img10 from '../../componant/assets/imgpsh_fullsize_anim (9).png';
import img12 from '../../componant/assets/imgpsh_fullsize_anim (12).png'
import moment from "moment";
import './HeaderBottom.css'
import { useDispatch, useSelector } from "react-redux";

import { timeupdatereducer } from "../Food-container/Redux/cartslice";

const HeaderBottam = () => {
  // Check if the user is logged in
  const isLoggedIn = localStorage.getItem('login_Token');
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState('');
  const pickupLabel = deliveryOption === 'delivery' ? 'Hours of Delivery' : 'Pickup time';

  const [selectedTime, setSelectedTime] = useState(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      return JSON.parse(storedDateTime).time;
    } else {
      return '';
    }
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      return moment(JSON.parse(storedDateTime).date);
    } else {
      return moment();
    }
  });
 
  useEffect(() => {
    const storedOrderData = localStorage.getItem('orderData');
    if (storedOrderData) {
      const parsedData = JSON.parse(storedOrderData);
      setSelectedLocation(parsedData.selectedLocation);
      setDeliveryOption(parsedData.deliveryOption);
    }
  }, []);

  useEffect(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    const currentTime = "12:00 PM"
    if (storedDateTime) {
      const parsedDateTime = JSON.parse(storedDateTime);
      setSelectedTime(parsedDateTime.time || currentTime);
      setSelectedDate(moment(parsedDateTime.date));
    }
  }, []);
  const value = useSelector(state => state.cartReducer)
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('selectedDateTime', JSON.stringify({ date: selectedDate.format('YYYY-MM-DD'), time: selectedTime }));
  }, [selectedDate, selectedTime,value?.selectedTime]);

  
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
    const currTime = JSON.stringify({ date: selectedDate.format('YYYY-MM-DD'), time: e.target.value });
    localStorage.setItem('selectedDateTime', currTime);
    dispatch(timeupdatereducer(currTime))
    // window.location.reload();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleLocationSelect = () => {
    handleCloseModal();
  };


  const handleDateChange = (e) => {
    setSelectedDate(moment(e.target.value));
    const currDate = JSON.stringify({ date: e.target.value, time: selectedTime });
    localStorage.setItem('selectedDateTime', currDate);
    dispatch(timeupdatereducer(currDate))
  };

  const generateOptions = () => {
    const options = [];
    let currentDate = moment();
    for (let i = 0; i < 7; i++) {
      let label;
      if (i === 0) {
        label = 'Today';
      } else if (i === 1) {
        label = 'Tomorrow';
      } else {
        label = currentDate.format('ddd, MMM DD');
      }
      options.push(
        <option key={i} value={currentDate.format('YYYY-MM-DD')}>
          {label}
        </option>
      );
      currentDate = currentDate.add(1, 'day');
    }
    return options;
  };

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const hour12 = (hour % 12) || 12; // Convert to 12-hour format
            const ampm = hour < 12 ? 'AM' : 'PM'; // Determine AM/PM
            const startTime = `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`;
            const nextHour = hour + Math.floor((minute + 15) / 60);
            const nextHour12 = (nextHour % 12) || 12; // Convert to 12-hour format
            const nextAmpm = nextHour < 12 ? 'AM' : 'PM'; // Determine AM/PM for the next hour
            const endTime = `${nextHour12.toString().padStart(2, '0')}:${((minute + 15) % 60).toString().padStart(2, '0')} ${nextAmpm}`;
            timeSlots.push({ startTime, endTime });
        }
    }

    return timeSlots;
};

  const timeSlots = generateTimeSlots();
  return (
    <>
      <div className="header2">
        <div className="sub-header">
          <div className="text2">Your order</div>
          {
            isLoggedIn ? (
              ''
            ) : (
              <>
                <div className="header2-sign-up">
                  <div>
                    <HiOutlineUserCircle style={{ color: "#093455", fontSize: '16px', marginBottom: '2px' }} />
                  </div>
                  <div className="header2-text-sign-up nav-link" onClick={handleLoginClick}> Sign in</div>
                </div>
                <LoginRegister show={showLoginModal} onHide={() => setShowLoginModal(false)} />
              </>
            )
          }
        </div>
        <div className="box1">
          <div className="text1">Your Order</div>
          <div className="location">
            <button className="location-button ">
              <div className="button-address">
                {selectedLocation ? (
                  <>
                    <div className="button-location">
                      <p
                        style={{
                          marginBottom: '-9px',
                          color: '#fecd08',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '17px'
                        }}
                      >
                        {deliveryOption === "takeout" && (
                          <img
                            src={img10}
                            style={{ height: '22px', width: '22px', marginTop: '12px' }}
                            className="me-3 ms-3"
                            alt="Takeout"
                          />
                        )}
                        {deliveryOption === 'delivery' && (
                          <img
                            src={img12}
                            style={{ height: '22px', width: '22px', marginTop: '12px' }}
                            className="me-3"
                            alt="Delivery"
                          />
                        )}
                        {deliveryOption === 'takeout' ? 'Takeout' : 'Delivery'}{' '}
                      </p>
                      <span
                        style={{
                          color: '#6a6c6e',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          marginLeft: "55px"
                        }}
                      >
                        {selectedLocation.address}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="text3">Select an order type</div>
                )}
              </div>
              <div className="button-angle" onClick={handleOpenModal}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faChevronRight}
                  className="iconright"
                />
                {showModal && (
                  <OrderAddress showModal={showModal} onCloseModal={handleCloseModal} handleLocationSelect={handleLocationSelect} />
                )}
              </div>
            </button>
          </div>
        </div>
        <div className="box2">
          <div className="sub-box2">
            <div className="sub-sub-box2">
              <div className="text1">{pickupLabel}</div>
              <Card className='col rounded-3'>
                <select
                  id="weekly-dropdown"
                  value={selectedDate.format('YYYY-MM-DD')}
                  onChange={(e) => handleDateChange(e)}
                  className='rounded-3 border-secondary-subtle p-2'
                >
                  {generateOptions()}
                </select>
              </Card>
              <Card className='col rounded-3'>
                <select
                  id="timeSlotSelect"
                  className='rounded-3 border-secondary-subtle p-2'
                  onChange={handleTimeChange}
                  value={selectedTime}
                >
                  {timeSlots.map((slot, index) => (
                    <option key={index} value={slot.startTime} className="border-0 rounded-3">
                      {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                    </option>
                  ))}
                </select>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBottam;
