import React, { useEffect, useState } from 'react';
import Header from '../../componant/Header';
import axios from 'axios';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaShoppingBag } from "react-icons/fa";
import { MdDeleteOutline, MdModeEdit } from "react-icons/md";
import { ORDERADDRESSDELETE_URL, ORDERADDRESSLIST_URL } from '../../componant/config/constant';

const containerStyle = {
  width: '100%',
  height: '600px'
};
// for india
// const center = {
//   lat: 20.5937,
//   lng: 78.9629
// };
// for gujrat
// const center = {
//     lat: 22.2587,
//     lng: 71.1924
//   };
// for surat
const center = {
  lat: 21.1702,
  lng: 72.8311
};
export const Addresslist = () => {
  const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
  const token = isLoggedIn?.token;
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState([])


  useEffect(() => {
    fetchOrderList()
  }, [])
  const fetchOrderList = () => {
    axios.get(ORDERADDRESSLIST_URL, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('Orders:', response.data);
        setSelectedAddress(response.data)
      })
      .catch(error => {
        console.error('Error fetching order list:', error);
      });
  };
  const handleDeletAddress = (id) => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    const data = {
      "address_id": id,
    };
    console.log("sending id:", data); // Corrected console log message

    axios.delete(ORDERADDRESSDELETE_URL, {
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log("response:", response.data); // Corrected console log message
      fetchOrderList();
    }).catch(error => {
      console.log('Error deleting address:', error); // Corrected console log message
    });
  };

  return (
    <>
      <Header />
      <div className='container '>
        <div className='m-5 d-flex justify-content-between '>
          <div className='fw-bolder'>My Address</div>
          <Button className='text-dark fw-bolder'> <span onClick={() => { navigate('/locationaddress') }}>+</span>Add New</Button>
        </div>
        <Row>

          {
            selectedAddress.map((e, i) => (
              <Col lg={6} >
                <div key={i} className='m-2'>
                  <Card style={{ padding: '14px' }}>
                    <div className='d-flex justify-content-around'  >
                      <div>
                        {e.address_type === "Home" && <FaHome style={{ fontSize: "20px" }} /> || e.address_type === "Workspace" && <FaShoppingBag style={{ fontSize: "20px" }} />}
                      </div>
                      <div>
                        <span className='fs-6'>{e.address_type}</span>
                        {/* <span className='fs-6'>{e.id}</span> */}
                        <p>{e.address}</p>

                      </div>
                      <div style={{ display: 'flex' }}>
                        <MdModeEdit style={{ fontSize: "20px" }}
                          onClick={() => {
                            navigate(`/locationaddress/${e.id}`,
                              {
                                state: e
                              }
                            )
                          }} />
                        <MdDeleteOutline style={{ fontSize: "20px" }} onClick={() => handleDeletAddress(e.id)} />
                      </div>
                    </div>
                  </Card>
                </div>

              </Col>

            ))
          }

        </Row>


      </div>



    </>
  );
};
