import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL, BRAND_ID,} from '../../componant/config/constant';

export const Branch = (props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        
        if (BRAND_ID != id) {
            localStorage.removeItem('orderData')
            localStorage.removeItem('selectedItems')
            localStorage.removeItem('orderDisplayed')
            localStorage.removeItem('selectedDateTime')
            localStorage?.setItem('brandId', id)
            window.location = BASE_URL;
        }
    }, [])
    return <></>
}
