import Header from "../../componant/Header";
import { Footer } from "../../componant/footer";
import { GrRestaurant } from "react-icons/gr";
import { PiCookingPotLight } from "react-icons/pi";
import { MdFastfood } from "react-icons/md";
import { FaBowlFood } from "react-icons/fa6";
import './Aboutus.css';


const AboutUs = () => {
   return (
        <>
            <Header />
            <h1 className="text-h1">About Us</h1>
            <div className="container p-4">

                <div className="row row-cols-1 row-cols-lg-3">
                    <div className="col-lg-6">
                        <img
                            src="https://www.jbvfoods.com/wp-content/uploads/2021/08/vadapav-about.jpg"
                            width={"100%"}
                            height={"480px"}
                            alt="Jay Bhavani Vadapav"
                        />
                    </div>
                    <div className="col-lg-5 ms-lg-5 mt-3 mt-lg-0">
                        <div>
                            <h3 style={{ color: "rgb(9, 52, 85)" }}>Jay Bhavani Vadapav</h3>
                            <h3>
                                <span className="fw-bolder">DISCOVER MORE ABOUT US</span>
                            </h3>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            JAY BHAVANI has Emerged as a clear Fast-Food chain expanding at a good rate throughout Gujarat and having footage in Rajasthan, Madhya Pradesh, Greater NCR and Now poised to launch in Canada in September 2021. ( Store undergoing finishing touches).
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            JAY BHAVANI Menu has Vadapav, Dabeli, Sandwiches, Pizza, Muskabun, Hotdog, Burger, Maggi, French Fries, Pavbhaji, Pulao, Aloo Tikki, Poha, Chole Kulcha, Chaat-Pakodi, Chinese, South Indian, Fruit Juice, Milk Shakes, Tea/Coffee, Packaged Namkeen, Packaged Spices, etc.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            All the four Rajput brothers viz. Bhavarsinh, Manoharsinh, Gersinh (Girishbhai) and youngest of all Kishansinh Rajput have been proud to serve Ambavadi with some of its favourite food.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Also, spreading the tasty, yammy, quick serve food to the entire Globe.
                        </p>
                    </div>
                </div>

            </div>
            <div className="mt-5">
                <h2 className="text-center" style={{ color: "rgb(9, 52, 85)" }}>
                    Some of <p className="fw-bolder h2 text-dark">Our Values</p>
                </h2>
            </div>
            <section className="py-4">
                <div className="container">
                    <h4>Why Choose Us</h4>
                    <hr />
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                        <div className="col">
                            <div className="d-flex mx-2 my-2">
                                <div className="card rounded-0 shadow w-100 text-center py-5 justify-content-center" style={{ border: "none", minHeight: "200px" }}>
                                    <div className="card-body animation">
                                        <FaBowlFood style={{ fontSize: "2rem" }} className="icons" />
                                        <p className="my-3">FRESH DISHES</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex mx-2 my-2">
                                <div className="card rounded-0 shadow w-100 text-center py-5 justify-content-center" style={{ border: "none", minHeight: "200px" }}>
                                    <div className="card-body animation">
                                        <GrRestaurant style={{ fontSize: "2rem" }} className="icons" />
                                        <p className="my-3">MENU VARIETY</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex mx-2 my-2">
                                <div className="card rounded-0 shadow w-100 text-center py-5 justify-content-center" style={{ border: "none", minHeight: "200px" }}>
                                    <div className="card-body animation">
                                        <MdFastfood style={{ fontSize: "2rem" }} className="icons" />
                                        <p className="my-3">FAST DELIVERY</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex mx-2 my-2">
                                <div className="card rounded-0 shadow w-100 text-center py-5 justify-content-center" style={{ border: "none", minHeight: "200px" }}>
                                    <div className="card-body animation">
                                        <PiCookingPotLight style={{ fontSize: "2rem" }} className="icons" />
                                        <p className="my-3">FRESH INGREDIENTS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default AboutUs;
