import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { GEOLOCATION_URL, ORDERADDRESSADD_URL, ORDERADDRESSUPDATE_URL, SELECTED_BRANCH_ID, UPDATEPROFILE_URL } from '../../componant/config/constant';
import { Button, Col, Form, Row } from 'react-bootstrap';

export const Locationadress = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    const location = useLocation();
    const addressData = location.state || {};
    const from = location.state?.from;

    console.log('State from previous page:', from);
    const navigate = useNavigate();
    const { id } = useParams();
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressdata(prevData => ({
            ...prevData,
            [name]: value,
        }));
        console.log("Updated Addressdata:", { ...Addressdata, [name]: value });
    }
    const [Addressdata, setAddressdata] = useState({
        Address: '',
        selectedOptions: 'Home'
    })
    const [userData, setuserdata] = useState({})
    const containerStyle = {
        width: '100%',
        height: '600px'
    };

    const center = {
        lat: 21.1702,
        lng: 72.8311
    };

    const handlecustmordatashow = () => {
        return axios.get(UPDATEPROFILE_URL, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Branch-Id': SELECTED_BRANCH_ID,
            }
        })
            .then(res => {
                setuserdata({ ...res.data });
            });
    }

    const handleupdataeAddress = () => {
        const Updataedata = {
            "contact_person_name": userData.f_name,
            "contact_person_number": userData.phone,
            "floor": null,
            "house": null,
            "road": null,
            "address_type": Addressdata.selectedOptions,
            "address": Addressdata.Address,
            "longitude": lng,
            "latitude": lat,
        }
        console.log("Updataedata", Updataedata)
        axios.put(ORDERADDRESSUPDATE_URL + `/${id}`,
            Updataedata, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log("Responseupdate:", response);
                navigate("/address")
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }
    const handleMapClick = (event) => {
        const { lat, lng } = event.latLng.toJSON();
        setLat(lat);
        setLng(lng);
        axios.get(GEOLOCATION_URL + `?lat=${lat}&lng=${lng}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log("Response:", res.data);
                const address = res.data.results[0].formatted_address;
                setAddressdata(prevData => ({
                    ...prevData,
                    Address: address
                }));
                console.log("acaa::", address)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                // Handle errors
            });
    };

    const AddAddress = () => {
        const data = {
            "contact_person_name": userData.f_name,
            "contact_person_number": userData.phone,
            "floor": null,
            "house": null,
            "road": null,
            "address_type": Addressdata.selectedOptions,
            "address": Addressdata.Address,
            "longitude": lng,
            "latitude": lat,
        }
        console.log('data', data)
        axios.post(ORDERADDRESSADD_URL, data, {
            headers: {
                Authorization: `Bearer ${token}` // Add token for authenticated requests
            }
        })
            .then(response => {
                console.log("ResponseAdd:", response);
                navigate("/address")
                navigate("/payment")
                // You can access response data using response.data
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }
    useEffect(() => {
        if (addressData && Object.keys(addressData).length !== 0) {
            setAddressdata({
                Address: addressData.address || '',
                selectedOptions: addressData.address_type || 'Home'
            });
            setLat(addressData.latitude || null);
            setLng(addressData.longitude || null);
        }
    }, [addressData]);
    useEffect(() => {
        handlecustmordatashow()
    }, [])

    return (
        <>
            <div>
                <div className='btndiv mx-2'>
                    <button className='btnuper  border border-secondary ' onClick={() => { navigate('/account') }} >
                        <span><MdOutlineKeyboardArrowLeft /></span>
                        <span className='fw-normal' style={{ fontSize: '14px', padding: '8px 5px 8px 0px' }} >Back to MyProfile</span>
                    </button>
                </div>
                <p className='text-center'>Location Address</p>
            </div>
            <div className='container p-1 border'>
                <Row>
                    <Col lg={6} className='p-2'>
                        <LoadScript googleMapsApiKey="AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={14}
                                onClick={handleMapClick}
                            >
                                {/* You can add markers here if needed */}
                                <Marker position={center} />
                            </GoogleMap>
                        </LoadScript>
                        <div className='card mt-3'>
                            <div className='card-body'>
                                <Form>
                                    <Form.Group className='d-flex justify-content-around'>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Home"
                                                name="selectedOptions"
                                                value="Home"
                                                className='mx-1'
                                                checked={Addressdata.selectedOptions === "Home"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Home">Home</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Workspace"
                                                name="selectedOptions"
                                                value="Workspace"
                                                className='mx-1'
                                                checked={Addressdata.selectedOptions === "Workspace"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Workspace">Workspace</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="Other"
                                                name="selectedOptions"
                                                value="Other"
                                                className='mx-1'
                                                checked={Addressdata.selectedOptions === "Other"}
                                                onChange={handleAddressChange}
                                            />
                                            <label htmlFor="Other">Other</label>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>

                        </div>
                    </Col>
                    <Col lg={6} className='p-2'>
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title text-center mb-4">Delivery Address</h3>
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="address" className="form-label">Address line</label>
                                        <textarea className="form-control" id="address" value={Addressdata.Address} rows="2" placeholder="Enter your address"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="city" className="form-label">Street Number</label>
                                        <input type="text" className="form-control" id="city" placeholder="Enter your city" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="state" className="form-label">House/Floor number</label>
                                        <input type="text" className="form-control" id="state" placeholder="Enter your state" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="zipCode" className="form-label">Contact Person name</label>
                                        <input type="text" className="form-control" value={userData.f_name} id="zipCode" placeholder="Enter your zip code" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="contactNumber" className="form-label">Contact number</label>
                                        <input type="text" className="form-control" id="contactNumber" value={userData.phone} placeholder="Enter your contact number" />
                                    </div>

                                    <div className="text-center">
                                        {id == null ? (
                                            <button type="submit" className="btn btn-primary text-dark" onClick={AddAddress}>Save location</button>
                                        ) : (
                                            <button type="submit" className="btn btn-primary text-dark" onClick={handleupdataeAddress}>Update address</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>


        </>
    );
};
