import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './LoginRegister.css';
import axios from 'axios';
import { Login_API, REGISTER_API, FORGOTPASSWORD_URL } from '../config/constant';
export const LoginRegister = ({ show, onHide }) => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('login');
  const [formData, setFormData] = useState({
    f_name: '',
    l_name: '',
    phone: '',
    email: '',
    password: '',
    agreement: false,
  });
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorMessage1, setErrorMessage1] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [forgotPasswordValue, setForgotPasswordValue] = useState('');

  useEffect(() => {
    const checkFormValidity = () => {
      const { email_or_phone, password, phone, agreement } = formData;
      if (activeTab === 'login') {
        return email_or_phone && password;
      } else {
        return phone && password && agreement;
      }
    };

    setFormValid(checkFormValidity());
  }, [formData, activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numericValue = value.replace(/\D/g, '');

    // Update formData based on the input name
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : (type === 'tel' ? numericValue : value)
    }));
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    setIsButtonDisabled(true)
    try {
      const response = await axios.post(
        Login_API,
        {
          email_or_phone: formData.email_or_phone,
          password: formData.password
        }
      );
      if (response.status === 200) {
        const logintoken = JSON.stringify(response.data);
        const tokenPayload = response?.data?.token?.split('.')[1]; // Extracting the payload part of the JWT
        const decodedPayload = atob(tokenPayload); // Decoding the payload from Base64
        const modifiedPayload = decodedPayload.replace('sub', 'customer'); // Modify the payload as needed
        console.log('tokenData :: ', modifiedPayload, JSON.parse(modifiedPayload)); // Logging the modified payload
        localStorage.setItem('login_Token', logintoken); // Storing the original token in localStorage
        localStorage.setItem('customer_id', JSON.parse(modifiedPayload)?.customer);
        setFormData({
          email_or_phone: '',
          password: '',
          agreement: false,
        });
      }
      console.log("login:", response.data);
      onHide();
      navigate('/');

    } catch (error) {
      console.log("error")
      console.log("error", error)
      setErrorMessage1(error?.response?.data?.errors)
    }finally{
      setIsButtonDisabled(false)
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLoginSubmit(event);
    }
  };
  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(
        FORGOTPASSWORD_URL,
        {
          email_or_phone: forgotPasswordValue,
        }
      );
      console.log("Response from forgot password API:", response);
      setForgotPasswordValue('');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setForgotPasswordClicked(true);
  };

  const handleLoginFormToggle = () => {
    setForgotPasswordValue('');
    setForgotPasswordClicked(false);
  };

  // --------------------------------------------------------  REGISTER_API -------------------------------------------------
  const handleRegisterSubmit = async (event) => {

    event.preventDefault();

    console.log('Register form submitted', formData);
    try {
      const response = await axios.post(
        REGISTER_API,
        {
          f_name: formData.f_name,
          l_name: formData.l_name,
          email: formData.email,
          phone: formData.phone,
          password: formData.password
        }
      );
      if (response.status === 200) {
        const responseDataString = JSON.stringify(response.data);
        localStorage.setItem('register_Token', responseDataString);
        setFormData({
          f_name: '',
          l_name: '',
          phone: '',
          password: '',
          agreement: false,
        });
        setActiveTab('login');
        // toast.success('user Account Created sucessfully')
      }


    }
    catch (error) {
      console.log("error", error.response.data.errors)
      setErrorMessage(error.response.data.errors)
    }
  };

  // --------------------------------------------------------  REGISTER_API  -------------------------------------------------

  const handleForgotPasswordInputChange = (e) => {
    const { value } = e.target;
    setForgotPasswordValue(value);
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton={true} style={{ borderRadius: '20px 20px 0px 0px', background: '#fecd08' }}>
        <Modal.Title className='modal-title'>
          YOUR ACCOUNT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3' style={{ overflowY: 'scroll', maxHeight: '454px' }}>
        <div>
          <h4 className='pt-2 fw-semibold'>
            Hi there!
          </h4>
          <p className='fw-semibold'>
            Enjoy a hassle-free ordering experience, every time.
          </p>
        </div>

        <Tabs
          id="login-register-tabs"
          activeKey={activeTab}
          onSelect={handleTabSelect}
          className='d-flex justify-content-center LoginReg-tabs'
        >
          <Tab eventKey="login" title="Log in" className='p-2 tab'>
            {!forgotPasswordClicked && (
              <Form onSubmit={handleLoginSubmit} onKeyDown={handleKeyDown} className='form1'>
                <Form.Group controlId="loginphone">

                  <Form.Label className='fw-medium'>Phone<span className='text-danger'>*</span> </Form.Label>
                  <Form.Control
                    type="tel"
                    name='email_or_phone'
                    placeholder="enter valid phone number"
                    value={formData.email_or_phone}
                    onChange={handleInputChange}
                    className={`p-2 formControl ${errorMessage1 && errorMessage1.some(error => error.code === 'email_or_phone') ? 'is-invalid' : ''}`}
                    required
                  />
                  {errorMessage1 && errorMessage1.some(error => error.code === 'email_or_phone') && <div className="invalid-feedback">{errorMessage1.find(error => error.code === 'email_or_phone').message}</div>}
                </Form.Group>
                <Form.Group controlId="loginPassword" className='mt-3'>
                  <Form.Label className='fw-medium'>Password<span className='text-danger'>*</span></Form.Label>
                  <Form.Group>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={passwordVisible ? "text" : "password"}
                        name='password'
                        placeholder="enter password"
                        className={`p-2 formControl ${errorMessage1 && errorMessage1.some(error => error.code === 'password') ? 'is-invalid' : ''}`}
                        value={formData.password}
                        style={{ borderRight: "none" }}
                        onChange={handleInputChange}
                        required
                      />
                      <span
                        // variant="outline-secondary rounded-end"
                        className={`text-dark input-group-text rounded-end ${errorMessage1 && errorMessage1.some(error => error.code === 'password') ? 'border border-danger' : ''}`}
                        style={{ borderLeft: "none", borderColor: "#dee2e6" }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? "Hide" : "Show"}
                      </span>
                      {errorMessage1 && errorMessage1.some(error => error.code === 'password') && <div className="invalid-feedback">{errorMessage1.find(error => error.code === 'password').message}</div>}
                    </InputGroup>
                  </Form.Group>

                </Form.Group>
                <p className='text-warning text-end text-decoration-underline mt-2' style={{ cursor: 'pointer' }} onClick={handleForgotPassword}>Forgot password?</p>

                <Form.Group className="mt-3 mb-3" style={{ cursor: 'pointer' }}>
                  <Form.Check
                    required
                    name="agreement"
                    label="Remember my phone address."
                    checked={formData.agreement}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <div>
                  This site is protected by reCAPTCHA.
                </div>
              </Form>
            )}
            {forgotPasswordClicked && (
              <Form className='form2'>
                <Form.Group controlId="loginphone">
                  <Form.Label className='fw-medium'>Email<span className='text-danger'>*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    name="email_or_phone"
                    placeholder=""
                    value={forgotPasswordValue}
                    onChange={handleForgotPasswordInputChange}
                    className='p-2 formControl'
                    required
                  />
                  <p className='text-danger'>{errorMessage1.email}</p>
                </Form.Group>
                <div>
                  This site is protected by reCAPTCHA.
                </div>
                <p className='text-warning text-decoration-underline mt-2' style={{ cursor: 'pointer' }} disabled={isButtonDisabled} onClick={handleLoginFormToggle}>
                {isButtonDisabled ? 'Loading...' : 'Login'}</p>
              </Form>
            )}
          </Tab>

          {/* ---------------------------------- -------------------------------------- register section start----------------------------------------------------- */}
          <Tab
            eventKey="register"
            title="Create an account"
            className='p-2 tab' >
            <Form onSubmit={handleRegisterSubmit}>
              <Form.Group controlId="registerphone" className='mt-3' style={{ cursor: 'pointer' }}>
                <Form.Label className='fw-medium '>FirstName<span className='text-danger'>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="f_name"
                  placeholder="enter your firstname"
                  className={`p-2 formControl ${errorMessage && errorMessage.some(error => error.code === 'f_name') ? 'is-invalid' : ''}`}
                  value={formData.f_name}
                  onChange={handleInputChange}
                  required />
                {errorMessage && errorMessage.some(error => error.code === 'f_name') && <div className="invalid-feedback">{errorMessage.find(error => error.code === 'f_name').message}</div>}
              </Form.Group>
              <Form.Group controlId="registerphone" className='mt-3' style={{ cursor: 'pointer' }}>
                <Form.Label className='fw-medium '>LastName<span className='text-danger'>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="l_name"
                  placeholder="enter your lastname"
                  className={`p-2 formControl ${errorMessage && errorMessage.some(error => error.code === 'l_name') ? 'is-invalid' : ''}`}
                  value={formData.l_name}
                  onChange={handleInputChange}
                  required />
                {errorMessage && errorMessage.some(error => error.code === 'l_name') && <div className="invalid-feedback">{errorMessage.find(error => error.code === 'l_name').message}</div>}
              </Form.Group>
              <Form.Group controlId="registerphone" className='mt-3' style={{ cursor: 'pointer' }}>
                <Form.Label className='fw-medium '>Email<span className='text-danger'>*</span> </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="enter your email"
                  className={`p-2 formControl ${errorMessage && errorMessage.some(error => error.code === 'email') ? 'is-invalid' : ''}`}
                  value={formData.email}
                  onChange={handleInputChange}
                  required />
                {errorMessage && errorMessage.some(error => error.code === 'email') && <div className="invalid-feedback">{errorMessage.find(error => error.code === 'email').message}</div>}
              </Form.Group>
              <Form.Group controlId="registerphone" className='mt-3' style={{ cursor: 'pointer' }}>
                <Form.Label className='fw-medium '>Phone<span className='text-danger'>*</span> </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="enter valid phone number"
                  className={`p-2 formControl ${errorMessage && errorMessage.some(error => error.code === 'phone') ? 'is-invalid' : ''}`}
                  value={formData.phone}
                  onChange={handleInputChange}
                  required />

                {errorMessage && errorMessage.some(error => error.code === 'phone') && <div className="invalid-feedback">{errorMessage.find(error => error.code === "phone").message}</div>}
              </Form.Group>

              <Form.Group controlId="loginPassword" className='mt-3'>
                <Form.Label className='fw-medium'>Password<span className='text-danger'>*</span></Form.Label>
                <Form.Group>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={passwordVisible ? "text" : "password"}
                      name='password'
                      placeholder="enter password"
                      className={`p-2 formControl ${errorMessage && errorMessage.some(error => error.code === 'password') ? 'is-invalid' : ''}`}
                      value={formData.password}
                      style={{ borderRight: "none" }}
                      onChange={handleInputChange}
                      required
                    />
                    <span
                      // variant="outline-secondary rounded-end"
                      className={`text-dark input-group-text rounded-end ${errorMessage && errorMessage.some(error => error.code === 'password') ? 'border border-danger' : ''}`}
                      style={{ borderLeft: "none", borderColor: "#dee2e6" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? "Hide" : "Show"}
                    </span>
                    {errorMessage && errorMessage.some(error => error.code === 'password') && <div className="invalid-feedback">{errorMessage.find(error => error.code === 'password').message}</div>}
                  </InputGroup>
                </Form.Group>

              </Form.Group>


              <Form.Group className="mt-3 mb-3">
                <Form.Check
                  required
                  name="agreement"
                  label="Yes, I have read and agree with the terms of use and consent to my personal information being used and shared with service providers for the purposes set out in the terms of use."
                  checked={formData.agreement}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <div >
                This site is protected by reCAPTCHA.
              </div>
            </Form>

          </Tab>
          {/* -------------------------------------- register section start----------------------------------------- */}

        </Tabs>

      </Modal.Body>
      {/* <span><BiErrorCircle className='text-danger' /></span> */}
      {/* {
        errorMessage ?
        (
        <Card className=' border p-2' ><p className=' text-start' style={{color:'#F41832',fontSize:'17px'}}><span><BiErrorCircle  style={{color:'#F41832',fontSize:'17px'}} /></span> {errorMessage}</p></Card>) : ("")
      } */}


      {
        activeTab === 'login' ? (
          <>
            {!forgotPasswordClicked && (
              <Modal.Footer className='d-flex justify-content-around bg FooterLR'
                onClick={handleLoginSubmit} style={{ borderRadius: '0 0 20px 20px', cursor: 'pointer', backgroundColor: formValid ? '#fecd08' : '#fff', }}>
                <span onClick={handleLoginSubmit}>Login</span>
              </Modal.Footer>
            )}
            {forgotPasswordClicked && (
              <Modal.Footer className='d-flex justify-content-around bg FooterLR' onClick={() => { handleForgotPassword() }} style={{ borderRadius: '0 0 20px 20px', cursor: 'pointer', backgroundColor: formValid ? '#fecd08' : '#fff', }}>
                <span onClick={() => { handleForgotPassword() }}>Reset my password</span>
              </Modal.Footer>
            )}
          </>
        ) : (
          <>
            <Modal.Footer className='d-flex justify-content-around bg FooterLR' onClick={handleRegisterSubmit} style={{ borderRadius: '0 0 20px 20px', cursor: 'pointer', backgroundColor: formValid ? '#fecd08' : '#fff', cursor: 'pointer' }}>
              <span onClick={handleRegisterSubmit}>Create</span>
            </Modal.Footer>
          </>
        )
      }
    </Modal >
  );
};

export default LoginRegister;