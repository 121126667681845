import React, { useState, useEffect } from "react";
import "../Header/Header.css";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { BiUserCircle } from "react-icons/bi";
import { LoginRegister } from "../LoginRegister";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import { LOGOUT_URL, LOCATIONCONFIG_URL,SELECTED_BRANCH_ID, BRAND_ID, BRANCHIMAGEBASE_URL ,GEOLOCATION_URL} from "../config/constant";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { LuArrowDownUp } from "react-icons/lu";

const Header = ({latitude,longitude}) => {
  const [add, setAdd] = useState('')
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isLoggedIn =JSON.parse(localStorage.getItem("login_Token"))
  const [cartItemsCount, setCartItemsCount] = useState([]);
  const [branchlogo, setbranchLogo] = useState([]);
  const token = isLoggedIn?.token;
  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handlestoreData = () => {
    const storedata = localStorage.getItem("selectedItems");
    if (storedata) {
      const storedata = localStorage.getItem("selectedItems");
      setCartItemsCount(JSON.parse(storedata))
    }
  }

  const handleLogoChange = (SELECTED_BRANCH_ID) => {
    axios.get(LOCATIONCONFIG_URL + `/${SELECTED_BRANCH_ID} || ${BRAND_ID}`)
      .then(response => {
        if (response.status === 200) {
          const branchData = response.data.branches; // assuming branches is an array in response.data
          if (branchData.length > 0) {
            const logoUrl = BRANCHIMAGEBASE_URL + branchData[0].image; // assuming branch_logo is the field for the logo image URL
            setbranchLogo(logoUrl);
          } 
        } else {
          console.error('Unexpected response status:', response.status);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

const handleBrandchange= ()=>{
  localStorage.removeItem('brandId')
  localStorage.removeItem('selectedItems')
  localStorage.removeItem('orderData')
  localStorage.removeItem('selectedDateTime')
  // localStorage.removeItem('orderDisplayed')
window.location.reload()
}
useEffect(() => {
 
  handlestoreData();
  handleLogoChange(SELECTED_BRANCH_ID);

}, [SELECTED_BRANCH_ID,add]);
  const handleLogout = async () => {
   
    try {
      console.log("token::", token);
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }
      const response = await axios.put(LOGOUT_URL, {
        "cm_firebase_token": "@",
        "_method": "put",
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Branch-Id': SELECTED_BRANCH_ID,
        }
      });
      if (response.status === 200) {
        localStorage.removeItem("login_Token");
        localStorage.removeItem('selectedItems')
        window.location.reload();
        navigate("/");
        // toast.success('user Logout sucessfully');
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const isAccountPage = location.pathname === "/account";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbar
        expand="lg"
        style={{ backgroundColor: "#093455", height: "90px" }}
        className="navbar"
      >
        <div className="img-con">
        <img
          src={branchlogo || "https://storage.googleapis.com/ueat-assets/064e28bc-4519-4b68-9a88-bfc1c665ce2a.png"} 
          alt="Branch Logo"
          className="jss72"
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" ,objectFit:'cover'}}
        />
        </div>

        {cartItemsCount > 0 && <div className="cart-badge">{cartItemsCount}</div>}
        {isLoggedIn ? (
          <>
            {isAccountPage ? (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <div
                    className="header-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/account");
                    }}
                  >
                    MY PROFILE
                  </div>

                </Navbar.Collapse>
              </>

            ) : (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <div
                    className="header-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    ONLINE ORDERING
                   
                  </div>
                 
                </Navbar.Collapse>
                {/* <div className="text-white">{add}</div> */}
                <span className="text-white"><LuArrowDownUp   onClick={()=>handleBrandchange()}/></span>
              </>
            )}

            <Navbar.Text className="sign-up1 sign-up3" style={{ cursor: "pointer" }}>
              <Nav className="sub-sign-up1">
                <BiUserCircle style={{ fontSize: "25px" }} />
                <span className="text-sign-in1" style={{ marginLeft: "10px" }}> Welcome !
                </span>
              </Nav>
              <span className="mx-0"><Dropdown>
                <Dropdown.Toggle className="text-dark border-0 p-1" variant="outline-none" id="dropdown-basic">
                </Dropdown.Toggle>
                <Dropdown.Menu className="outine-none">
                  <Dropdown.Item href="/account">
                    MY PROFILE</Dropdown.Item>
                  <Dropdown.Item onClick={() => { handleLogout() }}>LOGOUT</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown></span>
            </Navbar.Text>

            {/* ...............size................................... */}
            <Navbar.Text className="sign-up1 sign-up2" style={{ cursor: "pointer" }}>
              <Nav className="sub-sign-up1">
                <BiUserCircle style={{ fontSize: "25px" }} />
                <MdOutlineArrowForwardIos onClick={handleShow} />
                <Offcanvas show={show} onHide={handleClose} placement="end">
                  <Offcanvas.Header className="shadow p-4">
                    <Offcanvas.Title><MdOutlineArrowBackIos onClick={handleClose} /><BiUserCircle style={{ fontSize: "25px" }} /></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="p-0 d-flex flex-column justify-content-between">
                    <div className="mx-3">
                      <div className="d-flex justify-content-between p-3 profile">
                        <Link to="/account" >My Profile</Link><MdOutlineArrowForwardIos style={{ color: "#cacaca" }} />
                      </div>
                      <div style={{ border: "1px dashed #cacaca" }}></div>
                    </div>
                    <div className="d-flex"><button className="btn bg-warning w-100" onClick={() => { handleLogout() }}>logout</button></div>

                  </Offcanvas.Body>
                </Offcanvas>
              </Nav>
            </Navbar.Text>
          </>
        ) : (
          <>
            <Navbar.Collapse id="navbarScroll">
              <div className="header-text" style={{ cursor: "pointer" }} onClick={() => { navigate('/') }}>ONLINE ORDERING</div>

            </Navbar.Collapse>
            <span className="text-white" ><LuArrowDownUp onClick={()=>handleBrandchange()} /></span>
            <Navbar.Text className="sign-up" style={{ cursor: 'pointer' }}>
              <Nav className="sub-sign-up" onClick={handleLoginClick}>
                <Nav>
                  <BiUserCircle style={{ color: "#FFD43B", fontSize: '25px' }} />

                </Nav>
                <Nav className="text-sign-in">Sign in</Nav>
              </Nav>
            </Navbar.Text>
          </>
        )
        }
      </Navbar>
      <LoginRegister
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
    </>
  );
};

export default Header;
