import React, { useEffect, useState } from 'react'
import { Modal, Card } from 'react-bootstrap'
import image2 from '../../componant/assets/81ce27ae-d988-4501-aa84-7333876e3d4a.png'
// import image3 from '../../componant/assets/imgpsh_fullsize_anim (1).png'
// import image4 from '../../componant/assets/imgpsh_fullsize_anim.png'
// import image5 from '../../componant/assets/imgpsh_fullsize_anim (2).png'
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import './option.css';

export const OptionModal = ({ show, onHide, itemName, selectedItem, selectedItems, price1, selectedItemId, selectedAddOns, currentSelectedProduct }) => {

  // console.log('all detail Of Product :: ',  currentSelectedProduct)
  const [cartCurrentProduct, setCartCurrentProduct] = useState({})

  const [selectedCards, setSelectedCards] = useState([]);
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(price1);
  const [loading, setLoading] = useState(true);
  const [addOnPrices, setAddOnPrices] = useState([]);
  // console.log("price1:::", price)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const countAmount = () => {
    const qty = cartCurrentProduct?.count;
    console.log('qty :: ', qty)

    const addOnAmount = cartCurrentProduct?.add_ons?.filter((e, index) => cartCurrentProduct?.selectedCards?.includes(index))?.reduce((sum, e) => sum + e?.price, 0)

    cartCurrentProduct.total = ((addOnAmount > 0 ? addOnAmount : 0) + cartCurrentProduct?.price) * qty;

    setCartCurrentProduct({ ...cartCurrentProduct })
  }

  const addAddOnToCartCurrentProduct = (addOnIndex = 0) => {
    if (addOnIndex >= 0) {
      const addOnData = cartCurrentProduct?.selectedCards ?? [];
      if (addOnData?.includes(addOnIndex)) {
        addOnData?.splice(addOnData.indexOf(addOnIndex), 1);
      } else {
        addOnData?.push(addOnIndex)
      }

      cartCurrentProduct.selectedCards = addOnData
      setCartCurrentProduct({ ...cartCurrentProduct })

      countAmount()
    
    }
  
  }

  const qtyPlus = () => {
    cartCurrentProduct.count = cartCurrentProduct?.count + 1
    setCartCurrentProduct({ ...cartCurrentProduct })
    countAmount()
  }

  const qtyMinus = () => {
    if (cartCurrentProduct.count > 1) {
      cartCurrentProduct.count = cartCurrentProduct?.count - 1
      setCartCurrentProduct({ ...cartCurrentProduct })
      countAmount()
    }
  }

  const addToCart = () => {
    const selectedData = {
      selectedCards: cartCurrentProduct?.selectedCards,
      count: cartCurrentProduct?.count,
      price: cartCurrentProduct?.price,
      total: cartCurrentProduct?.total,
      itemName: cartCurrentProduct?.name,
      selectedItemId: cartCurrentProduct?.id,
      selectedAddOns: cartCurrentProduct?.add_ons,
      selectedCardText: cartCurrentProduct?.add_ons?.filter((addOn, index) => cartCurrentProduct?.selectedCards?.includes(index))?.map(addOn => addOn.name)?.join(', '),
      selectedCardPrice: cartCurrentProduct?.add_ons?.filter((addOn, index) => cartCurrentProduct?.selectedCards?.includes(index))?.map(addOn => addOn.price * count)?.join(', ')
    }
    console.log("selectedData", selectedData)
    const existingItems = JSON.parse(localStorage.getItem('selectedItems')) || [];

    if (cartCurrentProduct?.indexCode >= 0) {
      existingItems[cartCurrentProduct?.indexCode] = selectedData
    } else {
      existingItems.push(selectedData);
    }

    localStorage.setItem('selectedItems', JSON.stringify(existingItems));
    window.location.reload()
    onHide();
  }

  // console.log('cartCurrentProduct :: ', cartCurrentProduct, currentSelectedProduct)


  useEffect(() => {
    setCartCurrentProduct(currentSelectedProduct)

    const initialAddOnPrices = selectedAddOns.map(addOn => 0);
    setAddOnPrices(initialAddOnPrices);
    const totalPrice = price1 + selectedAddOns.reduce((total, addOn, i) => {
      if (selectedCards.includes(i)) {
        return total + (addOn.price);
      }
      return total;
    }, 0);
    setPrice(totalPrice);
  }, [selectedCards, price1, selectedAddOns, currentSelectedProduct]);

  return (
    <>
      <div>
        <Modal show={show} onHide={onHide}>
          <Modal.Header className='option-header' style={{ background: '#fecd08', borderRadius: '20px 20px 0 0' }} closeButton>
            <Modal.Title>
              OPTION CHOICE
            </Modal.Title>
          </Modal.Header>
          {loading ? (<div class="loader-line"></div>) : (
            <>
              <Modal.Body style={{ overflowY: 'scroll' }}>
                <Modal.Body className='d-flex justify-content-center gap-3  imageBody'>
                  <img src={image2} alt='image' style={{ height: '100px', width: '100px' }} />
                  <div className='mt-4'>
                    <p className=' m-0  jss2298'>SELECTED ITEM</p>
                    <p className='h3'>{cartCurrentProduct?.name}</p>
                  </div>
                </Modal.Body>
                <Modal.Body className='d-flex justify-content-center gap-3' style={{ padding: '40px 0 20px 0', margin: '' }}>
                  <p className='text-center alian-items center pt-2 mt-1'
                    style={{
                      borderRadius: '50px',
                      height: '40px',
                      width: '40px',
                      border: '1px solid #a5a5a5',
                      color: 'rgb(254, 205, 8)'
                    }}>
                    1
                  </p>
                  <div style={{ lineHeight: '0.5' }}>
                    <p className='mt-2 fs-5 ' >
                      Possible modification
                    </p>
                    <p style={{
                      color: " #a5a5a5 ",
                      fontSize: " 17px ",
                      fontStyle: "normal ",
                      fontWeight: "bold ",
                    }}>
                      Choice: {cartCurrentProduct?.selectedCards?.length} / {cartCurrentProduct?.add_ons?.length}
                    </p>
                  </div>
                </Modal.Body>
                <div className='d-flex justify-content-center gap-4 text-center' style={{ padding: '3px 20px 50px 20px' }}>
                  {cartCurrentProduct?.add_ons?.map((e, index) => (
                    <Card
                      key={index}
                      // onClick={() => handleCardClick(index)}
                      onClick={() => addAddOnToCartCurrentProduct(index)}
                      className={` p-1 px-2 cursor ${cartCurrentProduct?.selectedCards?.includes(index) ? 'selectedCard' : ''}`}
                    >
                      <Card.Img
                        variant='top'
                        src={e.image}
                      />
                      <Card.Text className='card-text-p'>{e.name} </Card.Text>
                      <Card.Text className='card-text-p'><span className='text-success'>$</span>({e.price}) </Card.Text>
                    </Card>
                  ))}
                </div>
              </Modal.Body>
              <div className='border'></div>
              <div className="d-flex">
                <div className="flex-shrink-1 bg-white" style={{ borderRadius: "20px 0px 0px 20px", padding: "10px 35px" }}>
                  <div className='d-flex align-items-center gap-3' style={{ borderRadius: '20px', background: '#efefef', padding: '7px 20px' }}>
                    <div onClick={qtyMinus}><AiOutlineMinus /></div>
                    <div>{cartCurrentProduct?.count}</div>
                    <div onClick={qtyPlus}><AiOutlinePlus /></div>
                  </div>
                </div>
                <div className="p-2 w-100 bg-warning" style={{ borderRadius: "0px 0px 0px 0px" }}>
                  {/* <div className='d-flex justify-content-center pt-2 cursor ' style={{ height: '5vh' }} onClick={handleClose}> */}
                  <div className='d-flex justify-content-center pt-2 cursor ' style={{ height: '5vh' }} onClick={addToCart}>
                    Add. ${Number(cartCurrentProduct?.total).toFixed(2)}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      </div>
    </>
  )
}       