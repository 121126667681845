import React, { useState, useEffect } from "react";
import './payment.css'
import Header from "../../componant/Header";
import { Button, Card, Form } from "react-bootstrap";
import { OrderAddress } from "../../componant/OrderAdress";
import { MdKeyboardArrowRight, } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ORDERCANCELED_URL, ORDERDETAIL_URL, SELECTED_BRANCH_ID, SUGGESTIONSIMAGES_URL } from "../../componant/config/constant";
const OrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("order_id");
  const [orderDetail, setOrderDetail] = useState(null);
  const [cancelResponse, setCancelResponse] = useState("");
  const handleOrderDetail = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    if (token) {
      axios.get(ORDERDETAIL_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          order_id: id
        }
      })
        .then(response => {
          if (response.status === 200) {
            console.log('Order details:', response.data);
            setOrderDetail(response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching order details:', error);
        });
    } else {
      console.error('User is not logged in');
    }
  };


  const handleCancelOrder = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    if (token) {
      axios.post(ORDERCANCELED_URL, {
        order_id: id,
        "_method": 'put',
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Branch-Id': SELECTED_BRANCH_ID,
        }
      })
        .then(response => {
          if (response.status === 200) {
            console.log('Order cancelled:', response.data);

            const confirmClear = window.confirm('Are you sure you want to cancle this order');
            if (confirmClear) {
              setCancelResponse(response.data.message);
              navigate('/orderpage');
            }

          }
        })
        .catch(error => {
          console.error('Error cancelling order:', error);
        });
    } else {
      console.error('User is not logged in');
    }
  }

  useEffect(() => {
    handleOrderDetail();
    console.log('id:', id);
  }, [id]);

  if (!orderDetail) {
    return <div>Loading...</div>;
  }

  const order = orderDetail.find(order => order.order.id.toString() === id);

  console.log("order::", order.id)

  return (
    <>
      <Header />

      <div className="row" >

        <div className="col-12 ">
          <Card style={{ borderRadius: '20px', margin: '20px 30px' }}>
            <Card.Body style={{ background: '#efefef', borderRadius: '20px 20px 0 0' }}>
              <p className="card-text1">ORDER DETAILS</p>
              <p className="card-text2">{order?.order.delivery_time}</p>
              <p className="card-text3">(in 2 days)</p>
            </Card.Body>

            <Card.Body className="card-text4">
              <div className="d-flex justify-content-between">
                <div>      order Id:{order.order_id}</div>
                <span>  {order.order.order_type}</span>
              </div>
              <div className="d-flex justify-content-between">
                <div style={{ marginRight: "1.5rem", }}>Item:<span style={{ color: "rgb(254, 205, 8)" }}>{order.is_product_available}</span></div>
              </div>
            </Card.Body>
            <div style={{ border: '1px groove #efefef' }}></div>
            <Card.Body className="card-text4">
              <h5 className="text-center fw-bolder">Payment info</h5>
              <div className="d-flex justify-content-between">
                <div>status:<span style={{ color: "rgb(254, 205, 8)", marginLeft: '20px' }}> {order.order.payment_status}</span></div>
              </div>
              <div className="d-flex justify-content-between">
                <div style={{ marginRight: "1.5rem", }}>Method:<span style={{ color: "rgb(254, 205, 8)", marginLeft: '20px' }}>{order.order.payment_method}</span></div>
              </div>
            </Card.Body>
            <div style={{ border: '1px groove #efefef' }}></div>
            <Card.Body className="card-text4">
              <h5 className="text-center fw-bolder">Product info</h5>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <div>
                    <img src={`${SUGGESTIONSIMAGES_URL}${order.product_details.image}`} style={{ height: '100px', width: '100px' }} />
                  </div>
                  <div className="mx-2 d-flex flex-column">
                    <span>{order.product_details.name}</span>
                    <span >{order.product_details.price} $</span>
                  </div>
                </div>

                <div>
                  <span>Quntity:<span style={{ color: "rgb(254, 205, 8)" }}>{order.quantity}</span></span>
                </div>
              </div>
            </Card.Body>
            <div style={{ border: '1px groove #efefef' }}></div>
            <div className="d-flex justify-content-between align-items-center px-3">
              <p>SUB-TOTAL</p>
              <p>{order.product_details.price}$</p>
            </div>
            <div className="d-flex justify-content-between align-items-center px-3">
              <p>GST</p>
              <p>{order?.tax_amount}$</p>
            </div>

            <Card.Body className="card-text5">

              <div className="d-flex justify-content-between align-items-center mt-3">
                <p>Total</p>
                <p style={{ color: ' #fecd08' }}>
                  {order?.order.order_amount}$
                </p>
              </div>
            </Card.Body>
            {order.order.order_status === "pending" || order.order.order_status === "failed" ? (
              <div className="d-flex justify-content-center card-text6" onClick={handleCancelOrder} style={{ borderRadius: '0px 0px 20px 20px' }}>
                <p className="fw-bolder text-danger">{cancelResponse ? cancelResponse : "Cancel order"}</p>
              </div>
            ) : order.order.order_status === "canceled" ? (
              <div className="d-flex justify-content-center card-text6" style={{ borderRadius: '0px 0px 20px 20px' }}>
                <p className="fw-bolder text-danger">This order has been cancelled</p>
              </div>
            ) : (
              <div className="d-flex justify-content-center card-text6 bg bg-warning" style={{ borderRadius: '0px 0px 20px 20px' }} onClick={() => { navigate(`/trackorder?order_id=${id}`) }}>
                <p>Track order</p>
                <span><MdKeyboardArrowRight style={{ width: '23px' }} /></span>
              </div>
            )}
          </Card>
        </div>
      </div>

    </>

  );
};

export default OrderDetail;
