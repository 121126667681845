import React from 'react'
import { Footer } from '../../componant/footer'
import Header from '../../componant/Header'
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import './contect.css';

const libraries = ["places"];
const mapContainerStyle = {
    height: "80vh",
};
const center = {
    lat: 43.7420032,
    lng: -79.5705344,
};
const ContectUs = () => {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws",
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div className='loader-line'>Loading maps</div>;
    }


    return (
        <>
            <Header />
            <h1 className='text-h1'> Contect Us</h1>
            <section className="py-4">
                <GoogleMap mapContainerStyle={mapContainerStyle} zoom={1} center={center}>
                    <Marker position={center} />
                </GoogleMap>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="p-3 bg-light">
                                <form>
                                    <div className="form-body Add">
                                        <h6 className="mb-0 text-uppercase">Drop us a line</h6>
                                        <div className="my-3 border-bottom" />
                                        <div className="mb-3">
                                            <label className="form-label">Enter Your Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Enter Email</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Phone Number</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Message</label>
                                            <textarea className="form-control" rows={4} cols={4} defaultValue={""} />
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn border Add">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="p-3 bg-light">
                                <div className="address mb-3">
                                    <h6 className="mb-0 text-uppercase Add">Address</h6>
                                    <p className="mb-0 font-12">1224 Rue Bishop, Montréal, Québec, H3G 2E3</p>
                                </div>
                                <div className="phone mb-3">
                                    <h6 className="mb-0 text-uppercase Add">Phone</h6>
                                    <p className="mb-0 font-13">Toll Free (123) 472-796</p>
                                    <p className="mb-0 font-13">Mobile : +1234567890</p>
                                </div>
                                <div className="email mb-3">
                                    <h6 className="mb-0 text-uppercase Add">Email</h6>
                                    <p className="mb-0 font-13">mail@example.com</p>
                                </div>
                                <div className="working-days mb-3">
                                    <h6 className="mb-0 text-uppercase Add">WORKING DAYS</h6>
                                    <p className="mb-0 font-13">Mon - FRI / 9:30 AM - 6:30 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </div>
            </section>

            <Footer />
        </>

    )
}

export default ContectUs;