  import { Tabs, Tab } from "react-bootstrap";
  import { useEffect, useState } from "react";
  import './orderpage.css';
  import { IoCheckmarkCircle } from "react-icons/io5";
  import img11 from '../../componant/assets/imgpsh_fullsize_anim (10).png'
  import Header from "../../componant/Header";
  // import { Footer } from "../../componant/footer";
  import axios from "axios";
  import { ORDERLIST_URL } from "../../componant/config/constant";
  import { useNavigate } from "react-router-dom";

  const OrderHistory = () => {
    const navigate = useNavigate()
    const [key, setKey] = useState("Running");
    const [orderdata, setOrderdata] = useState([])


    const handleRunningOrderList = () => {
      const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
      const token = isLoggedIn?.token;
      if (token) {
        axios.get(ORDERLIST_URL, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(response => {
            if (response.status === 200) {
              console.log('Order list:', response.data);
              setOrderdata(response.data);
              console.log("orderdata::", orderdata)
            }
          })
          .catch(error => {
            console.error('Error fetching order list:', error);
          });
      } else {
        console.error('User is not logged in');
      }
    };

    const handledetailClick = (id) => {
      navigate(`/orderdetail?order_id=${id}`);
      console.log("order.id", id);
    };
    useEffect(() => {
      handleRunningOrderList()
    }, [])
    // Define an array of orders  
    const orders = [
      { id: 100028, status: "Pending", button: "Details", button1: "Track Order" },
      { id: 100027, status: "Pending", button: "Details", button1: "Track Order" },
      { id: 100026, status: "Pending", button: "Details", button1: "Track Order" },
      { id: 100024, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100023, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100021, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100020, status: "Pending", button: "Details", button1: "Track Order" },
      { id: 100019, status: "Out of Delivery", button: "Details", button1: "Track Order" },
      { id: 100017, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100016, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100010, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100008, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100006, status: "Pending", button: "Details", button1: "Track Order" },
      { id: 100005, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100004, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100003, status: "Confirmed", button: "Details", button1: "Track Order" },
      { id: 100002, status: "Confirmed", button: "Details", button1: "Track Order" }
    ];


    return (
      <>
        <Header />
        <div className="container">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 color tabs1"
          >

            <Tab eventKey="Running" title="Running" className="w-100 tab1">
              <div className="row ">
                {orderdata.map((order) => (
                    order.order_status == 'pending' &&'confirmed' && 'out_for_delivery' && 'processing' ? (<>
                  <div key={order.id} className="col-12 col-sm-12 col-mb-6 col-lg-6 ">
                    <div className="main shadow rounded-4 pb-4 p-3 mt-3">
                      <div className="d-flex ">
                        <img
                          src={img11}
                          alt=""
                          width={"80px"}
                          height={"80px"}
                          className="p-2"
                          style={{ backgroundColor: "#efefef", borderRadius: "5px" }}
                        />
                        <p className="ms-3">
                          order ID : {order.id} <span style={{ color: "#fecd08" }}>({order.order_type})</span>
                          <p>{order.details_count}  Item</p>
                          <p style={{ color: "#fecd08", marginTop: "-15px" }}><IoCheckmarkCircle /><span className="ms-1">{order.order_status}</span></p>
                        </p>
                      </div>
                      <button className="btn border" style={{ width: "245px", borderRadius: "10px" }}  onClick={() => handledetailClick(order.id)} >Details</button>
                      {
                        order.order_status == "confirmed" ? (<button className="btn ms-3 track" style={{ width: "245px", backgroundColor: "#fecd08", borderRadius: "10px" }}>{order.order_type}</button>
                        ) : ('')
                      }


                    </div>
                  </div>
                  </>) :('')
                ))}
              
              </div>
            </Tab>
            <Tab eventKey="History" title="History" className="w-100 tab1 text-dark">

              <div className="row">

                {orderdata.map((order) => (
                  order.order_status == 'canceled' && 'deliverd' && 'failed' ? (<>

                    <div key={order.id} className="col-12 col-sm-12 col-mb-6 col-lg-6 ">
                      <div className="main shadow rounded-4 pb-4 p-3 mt-3">
                        <div className="d-flex">
                          <img
                            src={img11}
                            alt=""
                            width={"80px"}
                            height={"80px"}
                            className="p-2"
                            style={{ backgroundColor: "#efefef", borderRadius: "5px" }}
                          />
                          <p className="ms-3">
                            order ID : {order.id} <span style={{ color: "#fecd08" }}>({order.order_type})</span>
                            <p>{order.details_count} item</p>
                            <p style={{ color: "#fecd08", marginTop: "-15px" }}><IoCheckmarkCircle /><span className="ms-1">{order.order_status}</span></p>
                          </p>
                        </div>
                        <button className="btn border " style={{ width: "250px", borderRadius: "10px" }}  onClick={() => handledetailClick(order.id)} 
                       >Details</button>
                        {/* {
                        order.order_status == "confirmed" ? (<button className="btn ms-3 track" style={{ width: "245px", backgroundColor: "#fecd08", borderRadius: "10px" }}>{order.order_type}</button>
                        ) : ('')
                      } */}
                        </div>
                        
                    </div>
                  </>) : ''
                  
                  ))}
            </div>
          </Tab>
        </Tabs>
      </div >
        {/* <Footer/> */ }
      </>
    
    );
  };

  export default OrderHistory;
