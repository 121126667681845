import { configureStore } from "@reduxjs/toolkit";
import cartslice, { cartreducer, timeupdatereducer } from "./cartslice";


const store = configureStore({
    reducer:{
        cartreducer:cartslice.reducer,
        timeupdatereducer:cartslice.reducer
    }
})
export default store;