import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form, Card, Nav, InputGroup, CardBody, FormControl, } from 'react-bootstrap';
import './OrderAdress.css';
import img3 from '../../componant/assets/bag_1250555.png'
import carimg from '../../componant/assets/imgpsh_fullsize_anim (4).png'
import { IoSearch, } from "react-icons/io5";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { BRAND_ID, LOCATIONCONFIG_URL, SELECTED_BRANCH_ID } from '../../componant/config/constant';
import moment from 'moment';
import { useNavigate, } from 'react-router-dom';


export const OrderAddress = ({ showModal, onCloseModal }) => {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState('By Address');
    const [deliveryOption, setDeliveryOption] = useState('takeout');
    const [deliveryByAddress, setdeliveryByAddress] = useState([]);
    const [showPickupSection, setShowPickupSection] = useState(false);
    const [selectedTime, setSelectedTime] = useState(() => {
        const storedDateTime = localStorage.getItem('selectedDateTime');
        if (storedDateTime) {
            return JSON.parse(storedDateTime).time;
        } else {
            return 'gggg';
        }

    }

    );
    const [loading, setLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [currentLatitude, setCurrentLatitude] = useState(null);
    const [currentLongitude, setCurrentLongitude] = useState(null);
    const [selectBrand, setSelectedBrand] = useState([])
    const [selectedDate, setSelectedDate] = useState(() => {
        const storedDateTime = localStorage.getItem('selectedDateTime');
        if (storedDateTime) {
            return moment(JSON.parse(storedDateTime).date);
        } else {
            return moment();
        }
    });
    // -----------------------------------------------------------serch delivery address------------------------------
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState(deliveryByAddress);
    const handleChange = (e) => {
        setSearchTerm(e.target.value)
        console.log(e.target.value)
        const results = deliveryByAddress.filter((user) =>
            (user.name.toLowerCase().includes(e.target.value.toLowerCase()) || user.address.toLowerCase().includes(e.target.value.toLowerCase()))

        );
        setFilteredData(results);
        console.log("filteredData", results)
    };
    // -----------------------------------------------------------serch delivery address------------------------------
    // ----------------------------------------------------- For time dropdowm start -----------------------------------------------
    const generateTimeSlots = () => {
        const timeSlots = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const hour12 = (hour % 12) || 12; // Convert to 12-hour format
                const ampm = hour < 12 ? 'AM' : 'PM'; // Determine AM/PM
                const startTime = `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`;
                const nextHour = hour + Math.floor((minute + 15) / 60);
                const nextHour12 = (nextHour % 12) || 12; // Convert to 12-hour format
                const nextAmpm = nextHour < 12 ? 'AM' : 'PM'; // Determine AM/PM for the next hour
                const endTime = `${nextHour12.toString().padStart(2, '0')}:${((minute + 15) % 60).toString().padStart(2, '0')} ${nextAmpm}`;
                timeSlots.push({ startTime, endTime });
            }
        }

        return timeSlots;
    };

    const timeSlots = generateTimeSlots();
    // ----------------------------------------------------- For time dropdowm  end-----------------------------------------------
    useEffect(() => {
        const storedOrderData = localStorage.getItem('orderData');
        if (storedOrderData) {
            const parsedData = JSON.parse(storedOrderData);
            setDeliveryOption(parsedData.deliveryOption);
            setSelectedLocation(parsedData.selectedLocation);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

    }, []);

    const handleLocationSelection = (location) => {
        setShowPickupSection(!showPickupSection);
        setSelectedLocation(location);
        localStorage.setItem('orderData', JSON.stringify({ deliveryOption, selectedLocation: location }));

    };
    const handleDeliveryOptionChange = (option) => {
        setDeliveryOption(option);
        localStorage.setItem('orderData', JSON.stringify({ deliveryOption: option, selectedLocation: selectedLocation }));
    };
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
        const parsedDateTime = JSON.parse(storedDateTime);
        const storedTime = parsedDateTime.time;
        const storedDate = parsedDateTime.date;
    }

    // ------------------------------------------------------ address Display start in  header and payment-----------------------------

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLatitude(position.coords.latitude);
                    setCurrentLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error('Error getting geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        Brandanbranchselectore(BRAND_ID,);
    }, [BRAND_ID]);
    const handleTabChange = (tabKey) => {
        setSelectedTab(tabKey);
    };
    const [alert, setAlert] = useState(null);

    const handleOkClick = () => {
        const selectedDateTime = {
            time: selectedTime,
            date: selectedDate.format('YYYY-MM-DD')
        };
        localStorage.setItem('selectedDateTime', JSON.stringify(selectedDateTime));
        onCloseModal();

        const storedData = localStorage.getItem('selectedItems');

        if (storedData && SELECTED_BRANCH_ID !== storedData.length) {
            const confirmClear = window.confirm('if You have some Foods in your cart then if you change your Branch, your Cart data will be Reset');
            if (confirmClear) {
                localStorage.removeItem('selectedItems');
            }
        }
        window.location.reload();
    }


    const Brandanbranchselectore = (BRAND_ID, currentLatitude, currentLongitude) => {
        if (BRAND_ID > 0) {
            axios.get(LOCATIONCONFIG_URL + `/${BRAND_ID}`)
                .then(response => {
                    console.log('Response:', response.data.restaurant_schedule_time);
                    console.log('Responsedadadad:', response.data);
                    if (response.status === 200) {
                        const distance = calculateDistance(
                            currentLatitude,
                            currentLongitude,
                            response.data.branches.latitude,
                            response.data.branches.longitude)
                        setdeliveryByAddress(response.data.branches);
                        console.log("setdeliveryByAddress", response.data.branches);

                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else {
            axios.get(LOCATIONCONFIG_URL)
                .then(response => {
                    console.log('Responsedadadad:', response.data);
                    if (response.status === 200) {
                        const filteredCampaigns = response.data.promotion_campaign.filter((e) => e.parent_id <= 0);
                        setSelectedBrand(filteredCampaigns);
                        console.log("setDeliveryByAddress", filteredCampaigns)
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }
    console.log("brand", selectBrand)
    // -------------------------------------------------------------------------------
    const [selectedCity, setSelectedCity] = useState("Choose City");
    const [error, setError] = useState("");
    const [locationsInCity, setLocationsInCity] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationsInSelectedCity, setLocationsInSelectedCity] = useState([]);
    console.log("seelctcity::", locationsInSelectedCity);

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        const confirmed = window.confirm(`Do you want to change the city to: ${selectedCity}?`);
        if (!confirmed) {
            return; 
        }
     setSelectedCity(selectedCity);
         if (selectedCity === "") {
            setLocationsInSelectedCity(deliveryByAddress);
            setSelectedLocations([]);
        } else {
            const filteredLocations = deliveryByAddress.filter(location => location.location === selectedCity);
            setLocationsInSelectedCity(filteredLocations);
            if (filteredLocations.length === 1) {
                setSelectedLocations([filteredLocations[0]]);
            } else {
                setSelectedLocations([]);
            }
        }
    };
    useEffect(() => {
        console.log("storedata", storedDateTime)
        if (storedDateTime) {
            const parsedDateTime = JSON.parse(storedDateTime);
            setSelectedTime(parsedDateTime.time);
        }
        localStorage.setItem('selectedDateTime', JSON.stringify({ date: selectedDate.format('YYYY-MM-DD'), time: selectedTime }));
    }, [selectedDate, selectedTime]);
    const handleSubmit = (event) => {
        event.preventDefault();

        if (selectedCity === "Choose City") {
            setError("Please select a city");
            return;
        }

        setError("");
    }
    // ------------------------------------- For  select Day start------------------------------------------
    const handleDateChange = (e) => {
        const newSelectedDate = moment(e.target.value);
        setSelectedDate(newSelectedDate);
        const selectedDateTime = { date: newSelectedDate.format('YYYY-MM-DD'), time: selectedTime };
        localStorage.setItem('selectedDateTime', JSON.stringify(selectedDateTime));
    };

    const handleTimeChange = (e) => {
        const newSelectedTime = e.target.value;
        setSelectedTime(newSelectedTime);
        const selectedDateTime = { date: selectedDate.format('YYYY-MM-DD'), time: newSelectedTime };
        localStorage.setItem('selectedDateTime', JSON.stringify(selectedDateTime));
    };

    const generateOptions = () => {
        const options = [];
        let currentDate = moment();
        for (let i = 0; i < 7; i++) {
            let label;
            if (i === 0) {
                label = 'Today';
            } else if (i === 1) {
                label = 'Tomorrow';
            } else {
                label = currentDate.format('ddd, MMM DD');
            }
            options.push(
                <option key={i} value={currentDate.format('YYYY-MM-DD')}>
                    {label}
                </option>
            );
            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }
        return options;
    };
    // ------------------------------------    For  select Day end -------------------------------------------
    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371;
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance;
    }



    // -------------------------------------------------------------------------------

    return (
        <>
            <Modal
                show={showModal}
                onHide={onCloseModal}
                className="d-flex flex-column "
                centered
            >
                {
                    BRAND_ID <= 0 ? (

                        <>
                            <Modal.Header className='d-flex justify-content-center Order-headerAddress' >

                                <Modal.Title className=' text-center fs-5  '>SELECTED BRAND </Modal.Title>

                            </Modal.Header>
                            <Row>
                                <Modal.Body className='ModalMainbody1'>
                                    {loading ? (<div class="loader-line" style={{ width: "100%" }}></div>) : (
                                        <>
                                            {
                                                selectBrand.map((e, i) => {
                                                    return (
                                                        <Card className='mt-3 addressmaincard' key={i}>
                                                            <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                <div>
                                                                    <p className='text-left' style={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0' }}>{e.name}</p>
                                                                    <span className='text-left'>{e.address}</span>
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        name="location"
                                                                        className='radiobtn '
                                                                        closeButton
                                                                        onClick={() => navigate(`branch/code/${e.id}`)}
                                                                    // checked={isSelected}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )

                                                })
                                            }
                                        </>

                                    )}


                                </Modal.Body>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Modal.Header className='d-flex justify-content-center Order-headerAddress' >

                                <Modal.Title className=' text-center fs-5  '>YOUR ORDER </Modal.Title>

                            </Modal.Header>
                            <Modal.Body className='ModalMainbody' >


                                <Row>
                                    <Col lg='12'>
                                        <div className='d-flex justify-content-center gap-3 align-items-center'>
                                            <div
                                                className={`d-flex gap-1 align-items-center px-2 rounded-5 Orderbtn1 ${deliveryOption === 'takeout' ? 'active' && 'bg-warning' : ''}`}
                                                onClick={() => handleDeliveryOptionChange('takeout')}
                                            >
                                                <span className='Orderbtnicon1'>
                                                    <img src={img3} alt='imag' style={{ height: '18px', width: '22px', marginTop: '-6px', cursor: "pointer" }} />
                                                </span>
                                                <span className='Orderbtn1' style={{ cursor: "pointer" }}>Takeout</span>
                                            </div>
                                            <div
                                                className={`d-flex gap-2 align-items-center px-3 rounded-5 Orderbtn2 ${deliveryOption === 'delivery' ? 'active' && 'bg-warning' : ''}`}
                                                onClick={() => handleDeliveryOptionChange('delivery')}
                                            >
                                                <span className='Orderbtnicon1'><img src={carimg} style={{ height: '18px', width: '22px', marginTop: '-6px', cursor: "pointer" }} /></span>
                                                <span className='Orderbtn2' style={{ cursor: "pointer" }}>Delivery</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>


                                <div className='border border-top mt-2' ></div>
                                {/* {loading ? (<div className="d-flex justify-content-center">
                                    <div className="spinner-border text-warning" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>) : ( */}
                                <>
                                    {deliveryOption === 'takeout' ? (
                                        <Modal.Body className=" d-flex flex-column model1 orderAddBody rounded-4 model3">
                                            <span className='fs-5 fw-bold text-center' style={{ color: ' rgb(51, 51, 51)' }}>Choose a restaurant</span>

                                            <Row>
                                                <Col md='12' className='d-flex justify-content-around '>
                                                    <div className='text-decoration-none p-0 AddressNav' >
                                                        <Nav variant="underline" defaultActiveKey="By Address"  >
                                                            <Nav.Item className='p-2 fw-medium'>
                                                                <Nav.Link
                                                                    eventKey='By Address '
                                                                    onClick={() => handleTabChange('By Address')}
                                                                    className={selectedTab === 'By Address' ? 'text-warning' : 'text-dark'}
                                                                >By address</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='p-2 fw-medium'>
                                                                <Nav.Link eventKey='By Location'
                                                                    onClick={() => handleTabChange('By Location')}
                                                                    className={selectedTab === 'By Location' ? 'text-warning' : 'text-dark'}
                                                                >By location</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='p-2 fw-medium' >
                                                                <Nav.Link
                                                                    eventKey='By City'
                                                                    onClick={() => handleTabChange('By City')}
                                                                    className={selectedTab === 'By City' ? 'text-warning' : 'text-dark'}
                                                                >By city</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                selectedTab === 'By Address' && (
                                                    <div>
                                                        <Form>
                                                            <Form.Label>Address</Form.Label>
                                                            <InputGroup className="mb-3">
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="Enter address"
                                                                    value={searchTerm}
                                                                    onChange={handleChange}
                                                                    style={{ borderRight: "none" }}
                                                                />
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip style={{ backgroundColor: 'yellow', position: "fixed" }}>Click to search</Tooltip>}
                                                                >
                                                                    <InputGroup.Text className='bg-white'><IoSearch style={{ color: "#fecd08" }} /></InputGroup.Text>
                                                                </OverlayTrigger>
                                                            </InputGroup>
                                                            <p className='mt-1 text-center text-warning text-decoration-underline fw-medium'>Address not found?</p>

                                                            <Form.Label>Apt#</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                            // value={address}
                                                            // onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                        </Form>
                                                        <div className='mt-3'>
                                                            {(searchTerm ? filteredData : deliveryByAddress).map((location, index) => {
                                                                const distance = calculateDistance(currentLatitude, currentLongitude, location.latitude, location.longitude);
                                                                const isSelected = selectedLocation && selectedLocation.name === location.name;
                                                                if (!selectedLocation || isSelected) {
                                                                    if (distance <= 15000) {
                                                                        return (
                                                                            <Card className='mt-3 addressmaincard' key={index}>
                                                                                <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                                    <div>
                                                                                        <p className='text-left' style={{ fontSize:'14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0', color: 'rgb(51, 51, 51)' }}>{location.name}</p>
                                                                                        <span className='text-left'>{location.address}</span>
                                                                                    </div>
                                                                                    <div className='mb-3'>
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            name="location"
                                                                                            className='radiobtn'
                                                                                            closeButton
                                                                                            onClick={() => handleLocationSelection(isSelected ? null : location)}
                                                                                            checked={isSelected}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </CardBody>
                                                                                {isSelected && (
                                                                                    <>
                                                                                        {/* Date and time selection section */}
                                                                                        <div className='border mt-3 mb-3'></div>
                                                                                        <h5 className='text-center fw-semibold'>Day and hour for pickup</h5>
                                                                                        <div className='d-flex gap-2 mt-3'>
                                                                                            <Card className='col rounded-3'>
                                                                                                <select
                                                                                                    id="weekly-dropdown"
                                                                                                    value={selectedDate.format('YYYY-MM-DD')}
                                                                                                    onChange={(e) => handleDateChange(e)}
                                                                                                    className='rounded-3 border-secondary-subtle p-2'
                                                                                                >
                                                                                                    {generateOptions()}
                                                                                                </select>
                                                                                            </Card>
                                                                                            <Card className='col rounded-3'>
                                                                                                <select
                                                                                                    id="timeSlotSelect"
                                                                                                    className='rounded-3 border-secondary-subtle p-2'
                                                                                                    onChange={handleTimeChange}
                                                                                                    value={selectedTime}
                                                                                                >
                                                                                                    {timeSlots.map((slot, index) => (
                                                                                                        <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                                            {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </Card>
                                                                                        </div>
                                                                                        <div className='border mt-3 mb-3'></div>
                                                                                        <div>
                                                                                            Notes for counter
                                                                                            <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}></Card.Body>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </Card>
                                                                        );
                                                                    }
                                                                    else if (currentLatitude == null && currentLongitude == null) {
                                                                        return (
                                                                            <Card className='mt-3 addressmaincard' key={index}>
                                                                                <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                                    <div>
                                                                                        <p className='text-left' style={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0', color: 'rgb(51, 51, 51)' }}>{location.name}</p>
                                                                                        <span className='text-left'>{location.address}</span>
                                                                                    </div>
                                                                                    <div className='mb-3'>
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            name="location"
                                                                                            className='radiobtn'
                                                                                            closeButton
                                                                                            onClick={() => handleLocationSelection(isSelected ? null : location)}
                                                                                            checked={isSelected}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </CardBody>
                                                                                {isSelected && (
                                                                                    <>
                                                                                        {/* Date and time selection section */}
                                                                                        <div className='border mt-3 mb-3'></div>
                                                                                        <h5 className='text-center fw-semibold'>Day and hour for pickup</h5>
                                                                                        <div className='d-flex gap-2 mt-3'>
                                                                                            <Card className='col rounded-3'>
                                                                                                <select
                                                                                                    id="weekly-dropdown"
                                                                                                    value={selectedDate.format('YYYY-MM-DD')}
                                                                                                    onChange={(e) => handleDateChange(e)}
                                                                                                    className='rounded-3 border-secondary-subtle p-2'
                                                                                                >
                                                                                                    {generateOptions()}
                                                                                                </select>
                                                                                            </Card>
                                                                                            <Card className='col rounded-3'>
                                                                                                <select
                                                                                                    id="timeSlotSelect"
                                                                                                    className='rounded-3 border-secondary-subtle p-2'
                                                                                                    onChange={handleTimeChange}
                                                                                                    value={selectedTime}
                                                                                                >
                                                                                                    {timeSlots.map((slot, index) => (
                                                                                                        <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                                            {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </Card>
                                                                                        </div>
                                                                                        <div className='border mt-3 mb-3'></div>
                                                                                        <div>
                                                                                            Notes for counter
                                                                                            <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}></Card.Body>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </Card>
                                                                        );
                                                                    }

                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                        <p className='mt-2 text-center text-warning text-decoration-underline fw-medium'>see more results(1)</p>
                                                    </div>
                                                )
                                            }


                                            {selectedTab === 'By Location' && (
                                                <>
                                                    {/* If there is no branch selected, show all branches */}
                                                    {
                                                        (!selectedLocation ? (
                                                            deliveryByAddress.map((location, index) => {
                                                                const distance = calculateDistance(currentLatitude, currentLongitude, location.latitude, location.longitude);
                                                                const isSelected = selectedLocation && selectedLocation.name === location.name;

                                                                return (
                                                                    <div key={index}>

                                                                        {distance <= 15000 ? (
                                                                            <Card className="mt-3">
                                                                                <Card.Body className="d-flex align-items-center justify-content-between Addcard1">
                                                                                    <div>
                                                                                        <p className="text-left font-weight-bold" style={{ fontSize: '14px' }}>{location.name}</p>
                                                                                        <span className="text-left">{location.address}</span>
                                                                                        <p className="text-left text-warning fs-6">{distance.toFixed(2)} km</p>
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            name="location"
                                                                                            className="radio-btn"
                                                                                            onClick={() => handleLocationSelection(isSelected ? null : location)}
                                                                                            checked={isSelected}
                                                                                        />
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <div key={selectedLocation.name}>
                                                                <Card className="mt-3">
                                                                    <Card.Body className="d-flex align-items-center justify-content-between Addcard1">
                                                                        <div>
                                                                            <p className="text-left font-weight-bold" style={{ fontSize: '14px', color: 'rgb(51, 51, 51)' }}>{selectedLocation.name}</p>
                                                                            <span className="text-left">{selectedLocation.address}</span>
                                                                            <p className="text-left text-warning fs-6">
                                                                                {calculateDistance(currentLatitude, currentLongitude, selectedLocation.latitude, selectedLocation.longitude).toFixed(2)} km
                                                                            </p>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                name="location"
                                                                                className="radio-btn"
                                                                                onClick={() => handleLocationSelection(null)}
                                                                                checked={true}
                                                                            />
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                                <div>
                                                                    <div className="border mt-3 mb-3" />
                                                                    <h5 className="text-center fw-semibold">Day and hour for pickup</h5>
                                                                    <div className="d-flex gap-2 mt-3">
                                                                        <Card className="col rounded-3">
                                                                            <select
                                                                                id="weekly-dropdown"
                                                                                value={selectedDate.format('YYYY-MM-DD')}
                                                                                onChange={handleDateChange}
                                                                                className="rounded-3 border-secondary-subtle p-2"
                                                                            >
                                                                                {generateOptions()}
                                                                            </select>
                                                                        </Card>
                                                                        <Card className="col rounded-3">
                                                                            <select
                                                                                id="timeSlotSelect"
                                                                                className="rounded-3 border-secondary-subtle p-2"
                                                                                onChange={handleTimeChange}
                                                                                value={selectedTime}
                                                                            >
                                                                                {timeSlots.map((slot, index) => (
                                                                                    <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                        {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </Card>
                                                                    </div>
                                                                    <div className="border mt-3 mb-3" />
                                                                    <div>
                                                                        Notes for counter:
                                                                        <Card.Body className="rounded-3 border" style={{ minHeight: '60px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <p className="mt-2 text-center text-warning text-decoration-underline fw-medium"> No Branch available</p>
                                                </>
                                            )}

                                            {selectedTab === 'By City' && (
                                                <div>
                                                    <Form onSubmit={handleSubmit}>
                                                        <Form.Select aria-label="Default select example" value={selectedCity} onChange={handleCityChange}>
                                                            <option value="">Choose City</option>
                                                            {deliveryByAddress.map((city, index) => {
                                                                const distance = calculateDistance(currentLatitude, currentLongitude, city.latitude, city.longitude);
                                                                if (distance <= 15000) {
                                                                    return <option key={index} value={city.value}>{city.location}</option>;
                                                                }
                                                            })}
                                                        </Form.Select>
                                                        {error && <p>{error}</p>}
                                                    </Form>
                                                    <div>
                                                        {locationsInSelectedCity.map((location, index) => {
                                                            const isSelected = selectedLocation && selectedLocation.name === location.name;
                                                            const distance = calculateDistance(currentLatitude, currentLongitude, location.latitude, location.longitude);
                                                            if (distance <= 15000) {
                                                                return (
                                                                    <Card className='mt-3' key={index}>
                                                                        <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                            <div>
                                                                                <p className='text-left' style={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0', color: 'rgb(51, 51, 51)' }}>{location.name}</p>
                                                                                <span className=' text-left'>{location.address}</span>
                                                                            </div>
                                                                            <div className='mb-3'>
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="location"
                                                                                    className='radiobtn'
                                                                                    closeButton
                                                                                    onClick={() => {
                                                                                        setShowPickupSection(!showPickupSection);
                                                                                        // Handle location selection
                                                                                        handleLocationSelection(isSelected ? null : location);
                                                                                    }}
                                                                                    checked={isSelected}
                                                                                />
                                                                            </div>
                                                                        </CardBody>

                                                                    </Card>
                                                                );
                                                            }

                                                        })}
                                                    </div>
                                                    {showPickupSection && (
                                                        <>
                                                            <div className='border mt-3 mb-3'></div>
                                                            <h5 className='text-center fw-semibold'>Day and hour for pickup</h5>
                                                            <div className='d-flex gap-2 mt-3'>
                                                                <Card className='col rounded-3 border-0 ' >
                                                                    <select
                                                                        id="weekly-dropdown"
                                                                        value={selectedDate.format('YYYY-MM-DD')}
                                                                        onChange={(e) => handleDateChange(e)}
                                                                        className='rounded-3 p-2 border-secondary-subtle '
                                                                    >
                                                                        {generateOptions()}
                                                                    </select>
                                                                </Card>
                                                                <Card className='col rounded-3'>
                                                                    <select
                                                                        id="timeSlotSelect"
                                                                        className='rounded-3 border-secondary-subtle p-2'
                                                                        onChange={handleTimeChange}
                                                                        value={selectedTime}
                                                                    >
                                                                        {timeSlots.map((slot, index) => (
                                                                            <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </Card>
                                                            </div>
                                                            <div className='border mt-3 mb-3'></div>
                                                            <div>
                                                                Notes for counter
                                                                <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}></Card.Body>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                        </Modal.Body>
                                    ) : (

                                        <Modal.Body className='model2 d-flex flex-column model1 orderAddBody rounded-4'>
                                            <span className='fs-5 fw-bold text-center' style={{ color: ' rgb(51, 51, 51)' }}>Enter your Address</span>
                                            <div>
                                                <Form>
                                                    <Form.Label >Address</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl
                                                            type="text"
                                                            placeholder="Enter address"
                                                            value={searchTerm}
                                                            onChange={handleChange}
                                                        />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip style={{ backgroundColor: 'yellow', position: "fixed" }}>Cilck to search</Tooltip>}
                                                        >
                                                            <InputGroup.Text className='bg-white'><IoSearch style={{ color: "#fecd08" }} /></InputGroup.Text>
                                                        </OverlayTrigger>
                                                    </InputGroup>

                                                    <p className='mt-1 text-center text-warning text-decoration-underline fw-medium'>Address not found?</p>

                                                    {searchTerm && filteredData.map((location, index) => {
                                                        const distance = calculateDistance(currentLatitude, currentLongitude, location.latitude, location.longitude);
                                                        const isSelected = selectedLocation && selectedLocation.name == location.name;
                                                        if (!selectedLocation || isSelected) {
                                                            if (distance <= 15000) {
                                                                return (
                                                                    <Card className='mt-3 addressmaincard' key={index}>
                                                                        <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                            <div>
                                                                                <p className='text-left' style={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0' }}>{location.name}</p>
                                                                                <span className='text-left'>{location.address}</span>
                                                                            </div>
                                                                            <div className='mb-3'>
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="location"
                                                                                    className='radiobtn'
                                                                                    closeButton
                                                                                    onClick={() => handleLocationSelection(isSelected ? null : location)}
                                                                                    checked={isSelected}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </CardBody>
                                                                        {isSelected && (
                                                                            <>
                                                                                {/* Date and time selection section */}
                                                                                <div className='border mt-3 mb-3'></div>
                                                                                <h5 className='text-center fw-semibold'>Day and hour for Delivery</h5>
                                                                                <div className='d-flex gap-2 mt-3'>
                                                                                    <Card className='col rounded-3'>
                                                                                        <select
                                                                                            id="weekly-dropdown"
                                                                                            value={selectedDate.format('YYYY-MM-DD')}
                                                                                            onChange={(e) => handleDateChange(e)}
                                                                                            className='rounded-3 border-secondary-subtle p-2'
                                                                                        >
                                                                                            {generateOptions()}
                                                                                        </select>
                                                                                    </Card>
                                                                                    <Card className='col rounded-3'>
                                                                                        <select
                                                                                            id="timeSlotSelect"
                                                                                            className='rounded-3 border-secondary-subtle p-2'
                                                                                            onChange={handleTimeChange}
                                                                                            value={selectedTime}
                                                                                        >
                                                                                            {timeSlots.map((slot, index) => (
                                                                                                <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                                    {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </Card>
                                                                                </div>
                                                                                <div className='border mt-3 mb-3'></div>
                                                                                <div>
                                                                                    Notes for counter
                                                                                    <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}></Card.Body>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Card>
                                                                );
                                                            }
                                                            else if (currentLatitude == null && currentLongitude == null) {
                                                                return (
                                                                    <Card className='mt-3 addressmaincard' key={index}>
                                                                        <CardBody className="d-flex align-items-center justify-content-between Addcard1">
                                                                            <div>
                                                                                <p className='text-left' style={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'normal', lineHeight: '0' }}>{location.name}</p>
                                                                                <span className='text-left'>{location.address}</span>
                                                                            </div>
                                                                            <div className='mb-3'>
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="location"
                                                                                    className='radiobtn'
                                                                                    closeButton
                                                                                    onClick={() => handleLocationSelection(isSelected ? null : location)}
                                                                                    checked={isSelected}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </CardBody>
                                                                        {isSelected && (
                                                                            <>
                                                                                {/* Date and time selection section */}
                                                                                <div className='border mt-3 mb-3'></div>
                                                                                <h5 className='text-center fw-semibold'>Day and hour for pickup</h5>
                                                                                <div className='d-flex gap-2 mt-3'>
                                                                                    <Card className='col rounded-3'>
                                                                                        <select
                                                                                            id="weekly-dropdown"
                                                                                            value={selectedDate.format('YYYY-MM-DD')}
                                                                                            onChange={(e) => handleDateChange(e)}
                                                                                            className='rounded-3 border-secondary-subtle p-2'
                                                                                        >
                                                                                            {generateOptions()}
                                                                                        </select>
                                                                                    </Card>
                                                                                    <Card className='col rounded-3'>
                                                                                        <select
                                                                                            id="timeSlotSelect"
                                                                                            className='rounded-3 border-secondary-subtle p-2'
                                                                                            onChange={handleTimeChange}
                                                                                            value={selectedTime}
                                                                                        >
                                                                                            {timeSlots.map((slot, index) => (
                                                                                                <option key={index} value={slot.startTime} className="border-0 rounded-3">
                                                                                                    {moment(slot.startTime, 'HH:mm').format('hh:mm A')}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </Card>
                                                                                </div>
                                                                                <div className='border mt-3 mb-3'></div>
                                                                                <div>
                                                                                    Notes for counter
                                                                                    <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}></Card.Body>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Card>
                                                                );
                                                            }

                                                        }
                                                        return null;
                                                    })}
                                                    <Form.Label >Apt#</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                    // value={address}
                                                    // onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </Form>
                                            </div>
                                        </Modal.Body>
                                    )}
                                </>
                                {/* )} */}

                            </Modal.Body>
                            <Modal.Footer className='Order-add-footer text-dark' style={{ cursor: 'pointer', background: "#fecd08" }} onClick={handleOkClick}  >
                                <span >Ok</span>
                            </Modal.Footer>
                        </>

                    )}

            </Modal >
        </>
    );
};
