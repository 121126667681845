

// export const BASE_URL = "http://localhost:3000/";
export const BASE_URL = (window?.location?.hostname?.includes('localhost')) ? "http://localhost:3000/" : `http://${window?.location?.hostname}/`;
export const API_URL = "https://admin.road2xtechnology.com/";

console.log("BASE_URL",BASE_URL,window?.location?.hostname )
export const Login_API =`${API_URL}api/v1/auth/login`;
export const REGISTER_API =`${API_URL}api/v1/auth/registration`;
export const IMAGEBASE_URL = `${API_URL}storage/app/public/category/`
export const BRANCHIMAGEBASE_URL = `${API_URL}storage/app/public/branch/`
export const SUGGESTIONSIMAGES_URL =`${API_URL}storage/app/public/product/`
export const SUGGESTIONS_URL =`${API_URL}api/v1/products/popular?limit=12&&offset=1&product_type=all/`
export const CATEGORY_URL  =`${API_URL}api/v1/categories`
export const SUBCATEGORY_URL  =`${API_URL}api/v1/categories/products/`
export const UPDATEPROFILE_URL  =`${API_URL}api/v1/customer/info`
export const UPDATEPROFILEDATA_URL  =`${API_URL}api/v1/customer/update-profile`
export const DETETEACCOUNT_URL = `${API_URL}api/v1/customer/remove-account`
export const LOCATIONCONFIG_URL = `${API_URL}api/v1/config`
export const FORGOTPASSWORD_URL = `${API_URL}api/v1/auth/forgot-password`
export const LOGOUT_URL = `${API_URL}api/v1/customer/cm-firebase-token`
export const COD_URL = `${API_URL}api/v1/customer/order/place`
export const ORDERLIST_URL =`${API_URL}api/v1/customer/order/list`
export const ORDERADDRESSLIST_URL =`${API_URL}api/v1/customer/address/list`
export const ORDERADDRESSDELETE_URL =`${API_URL}api/v1/customer/address/delete`
export const ORDERADDRESSADD_URL =`${API_URL}api/v1/customer/address/add`
export const ORDERADDRESSUPDATE_URL =`${API_URL}api/v1/customer/address/update`
export const ORDERDETAIL_URL =`${API_URL}api/v1/customer/order/details`
export const ORDERCANCELED_URL =`${API_URL}api/v1/customer/order/cancel`
export const GEOLOCATION_URL  =`${API_URL}api/v1/mapapi/geocode-api`    
export const PAYMENT_RECEIPT_URL = `${BASE_URL}payment-receipt/success`
export const TRACKORDER_URL = `${BASE_URL}api/v1/customer/order/track`

export const BRAND_ID = localStorage?.getItem('brandId')?? null

export const orderDataStore = localStorage?.getItem('orderData') ? JSON?.parse(localStorage?.getItem('orderData')) : {}
export const data = orderDataStore

export const SELECTED_BRANCH_ID = orderDataStore?.selectedLocation?.id?? null
