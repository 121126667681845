import { React, useEffect, useState } from 'react'
import Header from '../../componant/Header'
import Order from '../../componant/Order'
import FoodContainer from '../../componant/Food-container'
import { Footer } from '../../componant/footer'
import { Link } from 'react-router-dom'
import { GEOLOCATION_URL, SELECTED_BRANCH_ID } from '../../componant/config/constant'




export const HomePage = () => {

  const [showOrder, setShowOrder] = useState(false); 
  const [add, setAdd] = useState('')
  const isLoggedIn =JSON.parse(localStorage.getItem("login_Token"))
  const token = isLoggedIn?.token;
  useEffect(() => {

    const hasOrderBeenDisplayed = localStorage.getItem('orderDisplayed');
    if (!hasOrderBeenDisplayed) {
      setShowOrder(true); // Display the order modal
      localStorage.setItem('orderDisplayed', 'true'); // Set the flag in localStorage to indicate that the order has been displayed
    }
    navigator.geolocation.getCurrentPosition(pos => {
      const { latitude, longitude } = pos.coords;
      console.log("lat",latitude,"long::", longitude)
      const url = GEOLOCATION_URL+`?lat=${latitude}&lng=${longitude}`;
      fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Branch-Id': SELECTED_BRANCH_ID,
        }
      })
          .then(res => res.json())
          .then(data => {
              setAdd(data.results[0].formatted_address)
          });
  });
  }, []);

  return (

    <>
      <Header />
      <FoodContainer />
      {showOrder && <Order />}
       <div className='text-center text-decoration-underline text-primary ' ><Link to='/termsandcon' className='text-primary'>Terms and Conditions</Link></div>
      {/* <Footer /> */}

    </>
  )
}
