import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import deliveryboygif from '../../componant/assets/imgpsh_fullsize_anim.gif'
import './trackorder.css';
import { TRACKORDER_URL } from '../../componant/config/constant';

export const Trackorder = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("order_id");
  const [orderStatus, setOrderStatus] = useState(null);
  const [ordertype, setordertype] = useState(null)
  const handleTrackOrder = (id) => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    if (token) {
      axios.get(TRACKORDER_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Branch-Id': `${id}`
        },
        params: {
          order_id: id
        }
      })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setOrderStatus(response.data.order_status);
            setordertype(response.data.order_type)
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error?.response?.data?.errors);
        });
    }
  };

  const renderSteps = () => {
    const steps = [
      { label: 'Order Placed', status: 'pending' },
      { label: 'Order Confirmed', status: 'confirmed' },
      { label: 'Preparing Food', status: 'preparing_food' },
      { label: 'Food Delivered', status: 'delivered' },
    ];

    if (orderStatus === 'canceled') {
      return <li className="step-wizard-item">Order Canceled</li>;
    } else if (orderStatus === 'failed') {
      return <li className="step-wizard-item">Order Failed</li>;
    }
    if (ordertype === 'delivery') {
      steps.splice(3, 0, { label: 'On the Way', status: 'out_for_delivery' });
    }
  

    return steps.map((step, index) => (
      <li key={index} className={`step-wizard-item ${orderStatus === step.status ? 'current-item' : ''}`}>
        <span className="progress-count">{index + 1}</span>
        <span className="progress-label">{step.label}</span>
      </li>
    ));
  };



  useEffect(() => {
    handleTrackOrder(id);
  }, [id]);
  return (
    <>

      <div className='container  mt-5'>
        <section className="step-wizard  d-flex flex-column align-items-center">
          <div className="card border-0 mb-3" style={{ maxWidth: "300px" }}>
            <div className="card-body border-0 d-flex justify-content-center">
              <img src={deliveryboygif} alt="delivery man" style={{ maxWidth: "100%", height: "auto" }} />
            </div>
          </div>
          <ul className="step-wizard-list">
            {renderSteps()}
          </ul>
        </section>
      </div>
    </>
  );
};
