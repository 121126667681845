// import { ByAddress } from "../pages/ByAdress/ByAddress";
import { OrderAddress } from "../componant/OrderAdress";
import  {HomePage}  from '../pages/Home';
import Myprofile from "../pages/Myprofile";
import PaymentContainer from "../pages/Payment";
import OrderHistory from "../pages/order/orderpage";
import { TermsAndCondition } from "../pages/Terms";
import  ContectUs  from "../pages/Contectus";
import AboutUs from "../pages/About";
import Cart from "../pages/cart";
import { Branch } from "../pages/Branch";
import { Paymentsucess } from "../pages/payment suceess/paymentsucess";
import { Paymentsucesscopy } from "../pages/payment suceess/paymentsuccesscopy";
import OrderDetail from "../pages/Payment/orderDetail";
import { Addresslist } from "../pages/Addaddress/address";
import { Trackorder } from "../pages/order/Trackorder";
import { Locationadress } from "../pages/Addaddress/Locationadress";

export const Routesdata = [
    {
        path:'branch/code/:id',
        element:<Branch />
    },
    {
        path:'/payment-receipt/success',
        element:<Paymentsucess />
    },
    {
        path:'/paymentcopy/success/:id',
        element:<Paymentsucesscopy />
    }, {
        path:'/orderdetail',
        element:<OrderDetail />
    },
 {
        path:'/trackorder',
        element:<Trackorder />
    },
    {
        path:'/address',
        element:<Addresslist />
    },
    {
        path:'/locationaddress',
        element:<Locationadress />
    },
    {
        path:'/locationaddress/:id',
        element:<Locationadress />
    },
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/home',
        element: <HomePage />
    },
    {
        path :'/account',
        element:<Myprofile/>
    },{
        path :'/payment',
        element :<PaymentContainer/>
    },
    {
        path :'/orderpage',
        element :<OrderHistory/>
    },
    {
        path :'/termsandcon',
        element :<TermsAndCondition/>
    },
    {
        path :'/contectus',
        element :<ContectUs/>
    },
    {
        path :'/aboutus',
        element :<AboutUs/>
    },
    {
        path :'/cart',
        element :<Cart/>
    },
    {
        path:'*',
        element:<h1>404 Page Not found</h1>
    }
]