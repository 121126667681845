import React from 'react';
import { useParams } from 'react-router-dom'
import Header from '../../componant/Header'
import { Container, Row, Col, Image, } from "react-bootstrap";
import { BASE_URL } from '../../componant/config/constant';

export const Paymentsucesscopy = () => {
    const { id } = useParams();
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;

    return (
        <>

            <Header />
            <Container>
                <Row className="justify-content-center py-5">
                    <Col lg='12'>
                        <h4 className='text-center fs-2'>Order Placed successfully</h4>
                    </Col>
                    <Col lg="10" className='d-flex justify-content-center'>
                        <Image
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFTdVcBscF5TkFu0axNuwSbVBtnqo5vk-dzydRGGYZ3zfbEubMWkrTd5ZW_pYOlquX7JU&usqp=CAU"
                            style={{ height: '150px' }}
                        />
                    </Col>
                    <Col lg="12" className='text-center mt-2'>
                        <p>Order Placed successfully</p>
                        <p>Order ID: {id}</p>
                        <button className='btn btn-primary text-dark' onClick={() => {
                            window.location = BASE_URL
                        }}>
                            Back To Home
                        </button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
