import React, { useState, useEffect } from "react";
import './payment.css'
import img7 from '../../componant/assets/imgpsh_fullsize_anim (6).png'
// import img8 from '../../componant/assets/imgpsh_fullsize_anim (7).png'
// import img9 from '../../componant/assets/imgpsh_fullsize_anim (8).png'
import Header from "../../componant/Header";
import { Button, Card, Form, FormGroup } from "react-bootstrap";
import { OrderAddress } from "../../componant/OrderAdress";
import img10 from '../../componant/assets/imgpsh_fullsize_anim (9).png'
// import img6 from '../../componant/assets/imgpsh_fullsize_anim (5).png'
import img12 from '../../componant/assets/imgpsh_fullsize_anim (12).png'
import { IoChevronBack } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL, BASE_URL, COD_URL, ORDERADDRESSLIST_URL, SELECTED_BRANCH_ID, UPDATEPROFILEDATA_URL, UPDATEPROFILE_URL } from "../../componant/config/constant";
import moment from "moment";

const PaymentContainer = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const [showorderModal, setShoworderModal] = useState(false);
  const [cartDetail, setCartDetail] = useState([]);
  // const [tipPercentage, setTipPercentage] = useState(0);
  const [id, setId] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState('');
  const [selectedTime, setSelectedTime] = useState(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      return JSON.parse(storedDateTime).time;
    } else {
      return '';
    }
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDateTime = localStorage.getItem('selectedDateTime');
    if (storedDateTime) {
      return moment(JSON.parse(storedDateTime).date);
    } else {
      return moment();
    }
  });
  const [EditFormData, setEditFormData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    Phone: '',
    Email: ''
  })
  const [EditFormDataShow, setEditFormDataShow] = useState({});
  const [deliveryAddress, setdeliveryAddress] = useState([])
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
        const token = isLoggedIn?.token;
        const response = await axios.get(UPDATEPROFILE_URL, {
          headers: {
            // 'Branch-Id': '1',
            'Branch-Id': SELECTED_BRANCH_ID,
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setEditFormDataShow({ ...response.data, Email: response.data.email });
          setEditFormData({
            FirstName: response.data.f_name,
            LastName: response.data.l_name,
            Address: response.data.Address,
            Phone: response.data.phone,
            Birthdate: response.data.Birthdate,
            Gender: response.data.Gender,
            Email: response.data.email
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    console.log("tsysys::", total)
    fetchData();

  }, []);


  useEffect(() => {
    const storedOrderData = localStorage.getItem('orderData');
    if (storedOrderData) {
      const parsedData = JSON.parse(storedOrderData);
      setSelectedLocation(parsedData.selectedLocation);
      setDeliveryOption(parsedData.deliveryOption);
    }
    const storedItems = localStorage.getItem('selectedItems');
    if (storedItems) {
      setCartDetail(JSON.parse(storedItems));
      console.log('cartDetail', cartDetail)
    }
  }, [])
  // ...............showeditdata........................
  // -------------------------------------------------------------stripe payment----------------------------------
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoding] = useState(false)
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const [selectedAddress, setSelectedAddress] = useState('');

  useEffect(() => {
    // Retrieve selected address from local storage
    const storedAddress = localStorage.getItem('selectedAddress');
    if (storedAddress) {
      setSelectedAddress(storedAddress);
    }
    console.log("storedAddress", storedAddress)
  }, []);
  const handlePaymentSubmit = (e) => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    console.log("login::", isLoggedIn?.token);
    setLoding(true)
    axios.post(UPDATEPROFILEDATA_URL, {
      f_name: EditFormData.FirstName,
      l_name: EditFormData.LastName,
      phone: EditFormData.Phone,
      email: EditFormData.Email,
      "_method": 'put'
    }, {
      headers: {
        'Branch-Id': SELECTED_BRANCH_ID,
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.status === 200) {
        } else {
          console.log("snss", response.statusText)
        }
      })
      .catch(error => {
        console.error('Error:', error);
      }

      );
      setLoding(false)
    console.log("Selected Payment Method:", paymentMethod);
    if (deliveryOption === 'delivery' && (!deliveryAddress || deliveryAddress.length === 0)) {
      alert("Please select an address for delivery.");
      return;
    } else if (deliveryOption === 'delivery' && (!deliveryAddress || deliveryAddress.length === 0) && selectedAddress === '') {
      e.preventDefault();
      alert('Please select an address before proceeding.');
      return;
    }
    else if (!paymentMethod) {
      alert('Please select a payment method');
      return
    }
    if (paymentMethod == 'COD') {
      handlePaymentCod()
    }
    else if (paymentMethod == 'stripe') {
      handlePaymentStripe();
    }
 
  };

  // -------------------------------------------------------------stripe payment----------------------------------

  const handlePaymentStripe = async () => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    console.log("login token:", isLoggedIn?.token)
    const customerId = localStorage.getItem('customer_id');
    console.log("customer_id:", customerId);
    const apiUrl = btoa(`customer_id=${customerId}&&callback=${BASE_URL}payment-receipt&&order_amount=${cartAmountData?.grandTotal}&&status=&branch_id=${SELECTED_BRANCH_ID}`);
    window.location = `${API_URL}payment-mobile?token=${apiUrl}&payment_method=stripe&branch_id=${SELECTED_BRANCH_ID}`;
  }

  const handlePaymentCod = () => {
    if (typeof localStorage === 'undefined') {
      console.error('localStorage is not available');
      return;
    }
    const storeId = localStorage.getItem('selectedItemIds');
    const storedDateTime = localStorage.getItem('selectedDateTime');
    const storedOrderData = localStorage.getItem('orderData');
    const storedItems = localStorage.getItem('selectedItems');
    const isLoggedIn = localStorage.getItem('login_Token');
    let token;
    let cartDetail = [];
    let selectedTime, selectedDate, deliveryOption, selectedLocation

    // Set data from localStorage
    if (storeId) {
      setId(JSON.parse(storeId));
      console.log('Store ID:', id);
    }

    if (storedDateTime) {
      const parsedDateTime = JSON.parse(storedDateTime);
      selectedTime = parsedDateTime.time;
      selectedDate = moment(parsedDateTime.date);
      setSelectedTime(selectedTime);
      setSelectedDate(selectedDate);
    }

    if (storedOrderData) {
      const parsedData = JSON.parse(storedOrderData);
      selectedLocation = parsedData.selectedLocation;
      deliveryOption = parsedData.deliveryOption;
    }

    if (storedItems) {
      cartDetail = JSON.parse(storedItems);
      setCartDetail(cartDetail);
      console.log('Cart detail:', cartDetail);
    }

    if (isLoggedIn) {
      token = JSON.parse(isLoggedIn)?.token;
    }

    // Create headers
    const headers = {
      Authorization: `Bearer ${token}`, // Use Bearer token format
      'Branch-Id': selectedLocation.id,
    };
    const cartData = cartDetail.map(item => ({
      product_id: item.selectedItemId || item.productId || [],
      price: item.price || [],
      variant: [],
      variations: [],
      discount_amount: null,
      quantity: item.count || [],
      tax_amount: (item.price || 0) * (0.05),
      add_on_ids: [],
      add_on_qtys: [],
    }));

    const data = {
      cart: cartData,
      coupon_discount_amount: 0,
      coupon_discount_title: null,
      order_amount: cartAmountData?.grandTotal,
      order_type: deliveryOption,
      delivery_address_id: selectedAddress || 0,
      payment_method: 'cash_on_delivery',
      order_note: '',
      coupon_code: null,
      delivery_time: selectedTime,
      delivery_date: selectedDate.format('YYYY-MM-DD'),
      branch_id: selectedLocation.id,
      distance: 0,
    };
    console.log("data", data)
    axios.post(COD_URL, data, { headers })
      .then(response => {
        const orderId = response.data.order_id;
        console.log('Order placed successfully:', response.data);
        navigate(`/paymentcopy/success/${orderId}`);
        localStorage.removeItem('selectedItems');
        localStorage.removeItem('selectedDateTime');
        localStorage.removeItem('selectedId');
      })
      .catch(error => {
        console.error('Error placing order:', error);
        navigate('/paymentfail');
      });
  };

  console.log("id", id)
  console.log("cart:", cartDetail);

  const handleOpenModal = () => {
    setShowModal(true);
    setShoworderModal(true)
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShoworderModal(false)
  };


  const [cartAmountData, setCartAmountData] = useState({
    total: '0.00',
    gst: 0.05,
    qst: 0,
    tip: 0.00,
    grandTotal: 0.00
  })

  useEffect(() => {

  }, [])
  const fetchOrderList = () => {
    const isLoggedIn = JSON.parse(localStorage.getItem('login_Token'));
    const token = isLoggedIn?.token;
    axios.get(ORDERADDRESSLIST_URL, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('Orders:', response.data);
        setdeliveryAddress(response.data)
        localStorage.setItem('selectedAddress', selectedAddress)
      })
      .catch(error => {
        console.error('Error fetching order list:', error);
      });
  };
  const getCounting = () => {
    const cartData = JSON?.parse(localStorage?.getItem('selectedItems'))?.map((e) => e = { ...e, amount: e?.total || e?.price })
    const total = cartData.map(e => e.amount)?.reduce((a, b) => { return a + b }, 0);
    cartAmountData.total = total;
    cartAmountData.gstAmt = Number((total * cartAmountData?.gst).toFixed(2))
    cartAmountData.qstAmt = Number((total * cartAmountData?.qst).toFixed(2))
    cartAmountData.tipAmt = Number(((total * cartAmountData?.tip) / 100).toFixed(2))

    cartAmountData.grandTotal = Number(total + cartAmountData.gstAmt + cartAmountData?.qstAmt + cartAmountData?.tipAmt).toFixed(2)
    console.log('sss :: ', cartAmountData)
    setCartAmountData({ ...cartAmountData })
  }

  const manageTip = (e) => {
    cartAmountData.tip = e
    setCartAmountData({ ...cartAmountData })
    getCounting();
  }

  useEffect(() => {
    getCounting()
    fetchOrderList()
    console.log("ssgsg::", selectedAddress)
  }, [])


  return (
    <>
      <Header />

      <div className="row" >
        <div className="col-3 coldiv3 border border-end first-sec p-3"  >
          <div className=" jss6504">
            <img src={img7} alt="img" style={{ height: '50px', width: '150px' }} />
            <p className="jss3583">Your personal information</p>
          </div>
          {
            deliveryOption == 'delivery' ? (<div style={{ border: '1px dashed #D3D3D3', marginTop: '77px' }}></div>) :
              (<div style={{ border: '1px dashed #D3D3D3' }}></div>)
          }

          <p className="jss3583">Your order information</p>

        </div>
        <div className=" col-5 coldiv5 col-md-9  col-xl-5 border  border-top-0 px-0 py-0"  >
          <div className="mainDiv ">

            <p className="tip-text">TIP FOR THE COUNTER</p>
            <div className="button-container " >
              {
                [0, 10, 15, 18, 20]?.map((e) => <button className="Tip-button" onClick={() => manageTip(e)}>{e}%</button>)
              }
            </div>
          </div>

          {/* <div style={{ border: '1px dashed #efefef' }}></div> */}
          <div className="formdiv jss3515">
            <Form>
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="FirstName"
                  value={EditFormData.FirstName}
                  onChange={(e) => setEditFormData({ ...EditFormData, FirstName: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="LastName"
                  value={EditFormData.LastName}
                  onChange={(e) => setEditFormData({ ...EditFormData, LastName: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="Email"
                  value={EditFormData.Email}
                  onChange={(e) => setEditFormData({ ...EditFormData, Email: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  name="Phone"
                  value={EditFormData.Phone}
                  onChange={(e) => setEditFormData({ ...EditFormData, Phone: e.target.value })}
                />
              </Form.Group>
              {
                deliveryOption === 'delivery' && deliveryAddress.length > 0 ? (
                  <FormGroup className="mb-3">
                    <label htmlFor="address">Address:</label>
                    <select
                      id="address"
                      name="address"
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        setSelectedAddress(selectedId); // Update state
                        localStorage.setItem('selectedAddressId', selectedId); // Store in local storage
                      }}
                      className="form-control"
                      required
                    >
                      <option value="" disabled selected>Select an address</option>
                      {deliveryAddress.map((address, index) => (
                        <option key={index} value={address.id}>
                          {address.address_type}: {address.address}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                ) : (
                  // If no delivery address is available, navigate to the address page
                  deliveryOption === 'delivery' && (
                    <button
                      onClick={() => navigate("/locationaddress", { state: { from: '/payment' } })}
                      className="btn btn-primary text-dark mb-3"
                    >
                      Add Address +
                    </button>
                  )
                )

              }
              < Form.Group className="mb-3">
                <Form.Check
                  required
                  name="terms"
                  label="Receive a confirmation of your order by sms"
                />
                <span className="fst-italic text-body-secondary mx-4">Cellphone only</span>
              </Form.Group>
            </Form>
          </div>
          <div style={{ border: '1px dashed #efefef' }}></div>
          <div className="order-sec">
            <button className="location-button">
              <div className="button-address">
                {selectedLocation ? (
                  <>
                    <div className="button-location">
                      <p
                        style={{
                          marginBottom: '-9px',
                          color: '#fecd08',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '17px'
                        }}
                      >

                        {deliveryOption === "takeout" && (
                          <img
                            src={img10}
                            style={{ height: '22px', width: '22px', marginTop: '12px' }}
                            className="me-3 ms-3"
                          />
                        )}
                        {deliveryOption === 'delivery' && (
                          <img
                            src={img12}
                            style={{ height: '22px', width: '22px', marginTop: '12px' }}
                            className="me-3"
                          />
                        )}
                        {deliveryOption === 'takeout' ? 'Takeout' : 'Delivery'}{' '}
                      </p>
                      <span
                        style={{
                          color: '#6a6c6e',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          marginLeft: "55px"
                        }}
                      >
                        {selectedLocation.address}
                      </span>
                    </div>

                  </>

                ) : (
                  <div className="text3">Select an order type</div>
                )}
              </div>
              <div className="button-angle" onClick={handleOpenModal}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faChevronRight}
                  className="iconright"
                />
              </div>
            </button>
            {showModal && (
              <OrderAddress showModal={showorderModal} onCloseModal={handleCloseModal} />
            )}
            <div className="mt-2 jss3515">
              Notes for counter
              <Card.Body className='rounded-3 border' style={{ minHeight: '60px' }}>

              </Card.Body>
              <span className="fst-italic text-body-secondary">optional</span>
            </div>

            <div className="d-flex" style={{ gap: '20px' }}>
              {/* <h2>Select Payment Method</h2> */}
              <div className="payopt">
                <input
                  type="radio"
                  id="cod"
                  name="paymentMethod"
                  style={{ marginRight: '4px' }}
                  value="COD"
                  checked={paymentMethod === "COD"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="cod"  >Cash on Delivery (COD)</label>
              </div>
              <div className="payopt" style={{ gap: '20px' }} >
                <input
                  type="radio"
                  id="digital"
                  name="paymentMethod"
                  value="stripe"
                  style={{ marginRight: '4px' }}
                  checked={paymentMethod === "stripe"}
                  onChange={handlePaymentMethodChange}

                />
                <label htmlFor="digital">Stripe</label>
              </div>
              {/* <button onClick={handlePaymentSubmit}>Submit</button> */}
            </div>
          </div>
          <div style={{ border: '1px solid #efefef' }}></div>
          {/* <div className="col-3 coldiv3 border border-end" style={{ background: '#efefef' }}> </div>
          <div className="col-12 col-md-9 col-lg-9 col-xl-5 border border-end coldiv5"> */}

          <Form.Group style={{ padding: "20px 50px", }}>
            <Form.Check
              required
              name="agreement"
              label={
                <span>
                  Yes, I have read and agree with the <span style={{ color: '#fecd08', textDecoration: 'underline' }}>terms of use</span> and consent to my personal information being used and shared with service providers for the purposes set out in the terms of use.
                </span>
              }

            />
          </Form.Group>
          <div className="text-center payrow" disabled={loading} onClick={handlePaymentSubmit}>
            {loading ? 'Processing...' : 'Payment'}
          </div>


          {/* </div> */}
          <div className="cartbtn">
            <button className="btn border bg-white rounded-0 col-6" style={{ marginTop: "40px" }} onClick={() => { navigate('/') }}>
              <div className="d-flex justify-content-around">
                <div className="d-inline" onClick={() => { navigate('/') }}>
                  <span className="text-warning" >Edit</span>
                </div>
              </div>
            </button>
            <button className="btn bg-warning rounded-0 col-6" style={{ marginTop: "40px", color: "#093455" }} onClick={handlePaymentSubmit}>
              <span >pay</span>
            </button>

          </div>
        </div>
        <div className="col-4 coldiv4">
          <Card style={{ borderRadius: '20px', margin: '20px 30px' }}>
            <Card.Body style={{ background: '#efefef', borderRadius: '20px 20px 0 0' }}>
              <p className="card-text1">ORDER READY FOR</p>
              <p className="card-text2">{selectedTime}</p>
              <p className="card-text3">(in 2 days)</p>
            </Card.Body>
            <div style={{
              maxHeight: " 640px",
              overflow: " scroll",
              scrollbarWidth: "none"
            }}>
              {cartDetail.map((item, index) => (
                <Card.Body key={index} className="card-text4">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                      <div style={{ marginRight: "1.5rem" }}>{item.count}x</div>
                      <div>{item.itemName}</div>
                    </div>
                    <div>${Number(item.total || item.price).toFixed(2)}</div>
                  </div>


                </Card.Body>
              ))}
            </div>

            <div style={{ border: '1px groove #efefef' }}></div>
            <div className="d-flex justify-content-between align-items-center px-3">
              <p>SUB-TOTAL</p>
              <p>${Number(cartAmountData?.total).toFixed(2)}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center px-3">
              <p>GST</p>
              <p>${cartAmountData?.gstAmt}</p>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center px-3">
              <p>QST</p>
              <p>${cartAmountData?.qstAmt}</p>
            </div> */}
            <div className="d-flex justify-content-between align-items-center px-3">
              <p>TIP</p>
              <p>${cartAmountData?.tipAmt}</p>
            </div>
            <Card.Body className="card-text5">

              <div className="d-flex justify-content-between align-items-center mt-3">
                <p>Total</p>
                <p style={{ color: ' #fecd08' }}>
                  ${cartAmountData?.grandTotal}
                </p>
              </div>
            </Card.Body>

            <div className="d-flex justify-content-center card-text6"
              style={{
                position: 'sticky',
                bottom: '0',
                background: 'white',
                borderRadius: '0 0 20px 20px'
              }}
              onClick={() => { navigate('/') }}>
              <span><IoChevronBack style={{ width: '23px' }} onClick={() => { navigate('/') }} /></span>
              <p>Edit</p>
            </div>
          </Card>
        </div >
      </div >

    </>

  );
};

export default PaymentContainer;
