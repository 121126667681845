import { createSlice } from "@reduxjs/toolkit";

const cartslice = createSlice({
    name: 'cartSlice',
    initialState: {
        showCart: false,
        isTimeUpdate: false
    },
    reducers: {
        cartreducer: (state, action) => {
            console.log("action::", state.action)
            state.showCart = action?.payload
        },
        timeupdatereducer: (state, action) => {
            console.log("timeupdatereducer action::", action?.payload)
            state.isTimeUpdate = action?.payload
        }
    }
})

export const { cartreducer, timeupdatereducer } = cartslice.actions;
export default cartslice;